import React from "react";
import logo from "../../assets/logo.png";
import payFail from "../../assets/payFail.png";
import "./index.less";

const PayForPhoneFail = () => {
  return (
    <div className="payMain">
      <div className="payContainer">
        <div className="payContainerCenter">
          <div className="payHeader">
            <img src={logo} className="headerLogo" />
            <div className="headerTitle">效果图网</div>
          </div>
          <img className="payStatusIcon" src={payFail} />
          <div className="payStatusText">订单失败</div>
        </div>
      </div>
    </div>
  );
};

export default PayForPhoneFail;
