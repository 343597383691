import React, { useEffect, useRef, useState } from "react";
import { message } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUser } from "../../context";
import Login from "../../components/Login";
import { getRequest, postRequest } from "../../service/index"; // 引入封装的请求
import noData from "../../assets/noData.png";
import collectBlack from "../../assets/collectBlack.png";
import collectYellow from "../../assets/collectYellow.png";
import backToTop from "../../assets/backToTop.png";
import kefu from "../../assets/kefu.jpg";
import "./index.less";

const Home = () => {
  const [searchParams] = useSearchParams();
  const { userInfo } = useUser();
  const navigate = useNavigate();

  const [topNavList, setTopNavList] = useState([]);
  const [secondNavList, setSecondmNavList] = useState([]);
  const [topNavVal, setTopNavVal] = useState("");
  const [subNavVal, setSubNavVal] = useState("");
  const [images, setImages] = useState([]); // 存储图片数据
  const [loading, setLoading] = useState(true); // 加载状态
  const [isHasMore, setIsHasMore] = useState(false); // 是否还有数据来分页
  const observer = useRef(null); // 用于观察滚动到底部加载更多
  const lastImageRef = useRef();
  const imageRequestParam = useRef({ type: 2, id: 2, keyWord: "", nextId: 0 });
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [showSetTop, setShowSetTop] = useState(false);
  const showSetTopRef = useRef(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const keyWord = searchParams.get("keyWord");
    const tmpTopNavList = [];
    if (userInfo?.id) {
      getRequest("/api/user/token").then((res) => {
        if (res?.data) {
          localStorage.setItem("token", res.data);
        }
      });
    }

    if (keyWord?.length) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }

    getRequest("/api/category/categories").then((res) => {
      if (res?.datas && res.datas.length) {
        res.datas.forEach((item) => {
          let tmpChild = [];
          if (item?.childCategories?.length) {
            tmpChild = [
              { id: item.id, title: "全部" },
              ...item.childCategories,
            ];
            setSubNavVal(tmpChild[0].id);
          }
          tmpTopNavList.push({
            type: item.type,
            id: item.id,
            title: item.title,
            icon: item.icon,
            child: tmpChild,
          });
        });
        setTopNavList(tmpTopNavList);
        setTopNavVal(tmpTopNavList[0].id);
        imageRequestParam.current.type = tmpTopNavList[0]?.type;
        imageRequestParam.current.id = 0;
        imageRequestParam.current.keyWord = keyWord ? keyWord : "";
        imageRequestParam.current.nextId = 0;
        fetchImages(true);
      }
    });
  }, [searchParams]);

  useEffect(() => {
    const scrollContainer = document.getElementsByClassName(
      "mainContainerContent"
    )[0];
    scrollContainer.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const container = document.querySelector(".mainContainerContent");
    if (container) {
      // 当滚动超过50px时触发逻辑
      if (container.scrollTop > 50 && !showSetTopRef.current) {
        setShowSetTop(true);
        showSetTopRef.current = true;
      }
      if (container.scrollTop <= 0 && showSetTopRef.current) {
        setShowSetTop(false);
        showSetTopRef.current = false;
      }
    }
  };

  const checkMainNav = (item) => {
    setTopNavVal(item.id);
    if (item?.child?.length) {
      setSecondmNavList(item.child);
      setSubNavVal(item.child[0].id);
    } else {
      setSecondmNavList([]);
      setSubNavVal("");
    }
    if (item.type == 1) {
      imageRequestParam.current.type = 1;
      imageRequestParam.current.id = 0;
      imageRequestParam.current.keyWord = "";
      imageRequestParam.current.nextId = 0;
    } else if (item.type == 2) {
      imageRequestParam.current.type = 2;
      imageRequestParam.current.id = 0;
      imageRequestParam.current.keyWord = "";
      imageRequestParam.current.nextId = 0;
    } else {
      imageRequestParam.current.type = 0;
      imageRequestParam.current.id = item.id;
      imageRequestParam.current.keyWord = "";
      imageRequestParam.current.nextId = 0;
    }
    fetchImages(true);
  };

  const checkSubNav = (item) => {
    setSubNavVal(item.id);
    imageRequestParam.current.type = 0;
    imageRequestParam.current.id = item.id;
    imageRequestParam.current.keyWord = "";
    imageRequestParam.current.nextId = 0;
    fetchImages(true);
  };

  // 模拟获取图片数据的函数 category用来区分一级分类 0-其他；1-推荐；2-最新
  const fetchImages = async (isSwitchTab) => {
    if (imageRequestParam.current.nextId === -1) {
      setIsHasMore(false);
      return;
    }
    setLoading(true);
    // category为最新或推荐时使用type字段值，其他场景使用一级或二级分类id
    let category = imageRequestParam.current.type;
    let url = "/api/home/latest";
    if (imageRequestParam.current.type == 2) {
      url = "/api/home/latest";
    } else if (imageRequestParam.current.type == 1) {
      url = "/api/home/recommend";
    } else if (imageRequestParam.current.type == 0) {
      category = imageRequestParam.current.id;
      url = "/api/home";
    }
    let param;

    if (imageRequestParam.current.keyWord?.length) {
      url = "/api/home";
      param = {
        keyWord: imageRequestParam.current.keyWord,
        nextId: imageRequestParam.current.nextId,
      };
    } else {
      param = {
        category,
        keyWord: imageRequestParam.current.keyWord,
        nextId: imageRequestParam.current.nextId,
      };
    }

    postRequest(url, param).then((res) => {
      setLoading(false);
      if (res?.data) {
        if (isSwitchTab) {
          setImages(res.data.list);
        } else {
          setImages([...images, ...res.data.list]);
        }
        if (res.data.nextId == -1 || imageRequestParam.current.nextId === -1) {
          imageRequestParam.current.nextId = -1;
          setIsHasMore(false);
        } else {
          imageRequestParam.current.nextId = res.data.nextId;
          setIsHasMore(true);
        }
      }
    });
  };

  // 收藏
  const handleCollect = (e, flag, data) => {
    e.stopPropagation();
    if (!userInfo?.id) {
      message.error("未登录");
      setIsLoginOpen(true);
      return;
    }
    if (flag) {
      postRequest("/api/collect", { opusId: data.opusId }).then(() => {
        message.success(`收藏成功`);
        const tmpImages = JSON.parse(JSON.stringify(images));
        tmpImages.forEach((item) => {
          if (item.opusId === data.opusId) {
            item.hasCollected = true;
          }
        });
        setImages(tmpImages);
      });
    } else {
      postRequest("/api/collect/cancel", { opusId: data.opusId }).then(() => {
        message.success(`取消收藏成功`);
        const tmpImages = JSON.parse(JSON.stringify(images));
        tmpImages.forEach((item) => {
          if (item.opusId === data.opusId) {
            item.hasCollected = false;
          }
        });
        setImages(tmpImages);
      });
    }
  };

  // 实现滚动到底部时加载更多图片
  useEffect(() => {
    if (loading || !isHasMore) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchImages(); // 页面到底部时加载下一页数据
      }
    });

    if (lastImageRef.current) {
      observer.current.observe(lastImageRef.current);
    }
  }, [loading, isHasMore]);

  const jumpToDetail = (id) => {
    navigate(`/detail?opusId=${id}`);
  };

  const setTop = () => {
    const container = document.querySelector(".mainContainerContent");
    if (container) {
      container.scrollTop = 0; // 将滚动位置设置为顶部
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="homeContainer">
      <div className="rightNavigator">
        <div
          className="rightNavigatorItem1"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="connectUs" />
        </div>
        {showSetTop ? (
          <div className="rightNavigatorItem" onClick={setTop}>
            <div className="rightNavItemIcon4" />
          </div>
        ) : null}
      </div>

      <div class={`diytooltip ${isHovered ? "tipvisible" : ""}`}>
        <div className="tipTitle">客服咨询</div>
        <div className="tipSubTitle">周一至周六：08:30-18:00</div>
        <img className="tipErweima" src={kefu} />
        <div className="tipFooter">扫描二维码添加客服微信</div>
      </div>

      {showNav ? (
        <div className="homeTopNav">
          {topNavList.map((item) => {
            return (
              <div
                className={`${
                  topNavVal == item.id ? "topActive" : ""
                } homeTopNavItem`}
                key={`home${item.id}`}
                onClick={() => checkMainNav(item)}
              >
                <img className="itemIcon" src={item.icon} />
                <div className="itemText">{item.title}</div>
              </div>
            );
          })}
        </div>
      ) : null}

      {secondNavList.length && showNav ? (
        <div className="homeSecondNavNav">
          <div className="secondNavItem bold" key="secondNavAll">
            二级分类
          </div>
          {secondNavList.map((item) => {
            return (
              <div
                className={`secondNavItem ${
                  subNavVal == item.id ? "bold" : ""
                } pointer`}
                onClick={() => checkSubNav(item)}
                key={item.id}
              >
                {item.title}
              </div>
            );
          })}
        </div>
      ) : null}

      {images.length ? (
        <div className="homePicContainer">
          {images.map((image, index) => (
            <div
              className="imageItem"
              key={index}
              ref={index === images.length - 1 ? lastImageRef : null}
              onClick={() => jumpToDetail(image.opusId)}
            >
              <div className="image-grid">
                <img src={image.cover} />
                <div className="collectIcon">
                  {image.hasCollected ? (
                    <img
                      className="collectIconPic"
                      src={collectYellow}
                      onClick={(e) => handleCollect(e, false, image)}
                    />
                  ) : (
                    <img
                      className="collectIconPic"
                      src={collectBlack}
                      onClick={(e) => handleCollect(e, true, image)}
                    />
                  )}
                </div>
              </div>
              <div className="picTitle">{image.title}</div>
            </div>
          ))}
        </div>
      ) : (
        <img className="noData" src={noData} />
      )}

      {!isHasMore && images.length ? (
        <div className="hasNoMore">-太棒了，你已经浏览完全部内容-</div>
      ) : null}

      <Login
        isOpen={isLoginOpen}
        handleCloseModal={() => setIsLoginOpen(false)}
      />
    </div>
  );
};

export default Home;
