import React, { createContext, useContext, useState, useEffect } from "react";
import { message } from "antd";
import { useLocation } from "react-router-dom";
import { getRequest } from "../service/index"; // 引入封装的请求
// 创建 UserContext
const UserContext = createContext();

// UserProvider 组件
export const UserProvider = ({ children }) => {
  const location = useLocation();
  const [userInfo, setUserInfo] = useState(null);
  const [isShowMaskForWindow, setIsShowMaskForWindow] = useState(false);

  const fetchUserInfo = async () => {
    getRequest("/api/user/info").then((res) => {
      if (res && res?.data) {
        setUserInfo(res.data);
      } else {
        setUserInfo(null);
      }
    });
  };

  const setUserInfoCb = (data) => {
    setUserInfo(data);
  };

  useEffect(() => {
    if (
      location.pathname.includes("secure") ||
      location.pathname.includes("pay")
    ) {
      return;
    }
    window.setUserInfoCb = setUserInfoCb;
    fetchUserInfo();
  }, []);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        fetchUserInfo,
        setUserInfoCb,
        setIsShowMaskForWindow,
        isShowMaskForWindow,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// 自定义 Hook 方便使用
export const useUser = () => {
  return useContext(UserContext);
};
