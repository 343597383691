import React, { useEffect, useState } from "react";
import { Pagination, message } from "antd";
import { useNavigate } from "react-router-dom";
import collectYellow from "../../assets/collectYellow.png";
import { getRequest, postRequest } from "../../service/index"; // 引入封装的请求
import "./index.less";

const Collect = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]); // 存储图片数据
  const [total, setTotal] = useState(0);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    fetchImages(0);
  }, []);

  // 模拟获取图片数据的函数
  const fetchImages = async (page) => {
    getRequest("/api/collect", { page, size: 15 }).then((res) => {
      if (res?.data) {
        setTotal(res.data.total || 0);
        setImages(res.data.list || []);
      }
    });
  };

  const onPageChange = (page) => {
    setPageNum(page);
    fetchImages(page - 1);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>上一页</a>;
    }
    if (type === "next") {
      return <a>下一页</a>;
    }
    return originalElement;
  };

  // 取消收藏
  const handleCollect = (flag, data) => {
    postRequest("/api/collect/cancel", { opusId: data.opusId }).then(() => {
      message.success(`取消收藏成功`);
      fetchImages(pageNum - 1);
    });
  };

  const jumpToDetail = (id) => {
    navigate(`/detail?opusId=${id}`);
  };

  return (
    <div className="collectMain">
      <div className="collectPicContainer">
        {images.length
          ? images.map((image, index) => (
              <div
                key={image.opusId}
                className="imageItem"
                onClick={() => jumpToDetail(image.opusId)}
              >
                <div className="image-grid">
                  <img src={image.cover} />
                  <div className="collectIcon">
                    <img
                      className="collectIconPic"
                      src={collectYellow}
                      onClick={() => handleCollect(false, image)}
                    />
                  </div>
                </div>
                <div className="picTitle">{image.title}</div>
              </div>
            ))
          : null}
      </div>

      {total > 40 ? (
        <Pagination
          className="paginationContainer"
          total={total}
          current={pageNum}
          itemRender={itemRender}
          showSizeChanger={false}
          onChange={onPageChange}
        />
      ) : null}
    </div>
  );
};

export default Collect;
