import React, { useEffect, useState, useRef } from "react";
import { message, Modal } from "antd";
import axios from "axios";
import { useUser } from "../../context";
import { useSearchParams, useNavigate } from "react-router-dom";
import Login from "../../components/Login";
import hasChecked from "../../assets/hasChecked.png";
import defaultAvatar from "../../assets/defaultAvatar.png";
import weichatLogo from "../../assets/weichatLogo.png";
import zhifubao from "../../assets/zhifubao.png";
import collectBlack from "../../assets/collectBlack.png";
import collectYellow from "../../assets/collectYellow.png";
import downloadBlack from "../../assets/downloadBlack.png";
import shareBlack from "../../assets/shareBlack.png";
import backToTop from "../../assets/backToTop.png";
import { getRequest, postRequest } from "../../service/index"; // 引入封装的请求
import "./index.less";

let opusId = "";
let interval;
let timeout;

const Detail = () => {
  const navigate = useNavigate();
  const { userInfo, setIsShowMaskForWindow } = useUser();

  const [searchParams] = useSearchParams();
  const [title, setTitle] = useState("");
  const [hasCollected, setHasCollected] = useState(false);
  const [createTime, setCreateTime] = useState("2024-08-09 20:08:00");
  const [authorAvatar, setAuthorAvatar] = useState(defaultAvatar);
  const [watched, setWatched] = useState(0);
  const [collected, setCollected] = useState("0");
  const [imgs, setImgs] = useState([]);
  const [footerImgs, setFooterImgs] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [price, setPrice] = useState(0);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [erweima, setErweima] = useState("");
  const [isShowMask, setIsShowMask] = useState(false);
  const [maskStatus, setMaskStatus] = useState("");
  const [showSetTop, setShowSetTop] = useState(false);
  const showSetTopRef = useRef(false);

  const canDownload = useRef(false);
  const userId = useRef("");

  const clearAll = () => {
    if (timeout) {
      clearInterval(timeout);
      timeout = null;
    }
    if (interval) {
      clearInterval(interval);
      interval = null;
    }
  };

  const handleScroll = () => {
    // 获取目标元素的位置
    const target = document.getElementById("targetItem");
    const targetPosition = target.getBoundingClientRect().top;

    const container = document.querySelector(".mainContainerContent");
    if (container) {
      // 当滚动超过50px时触发逻辑
      if (container.scrollTop > 50 && !showSetTopRef.current) {
        setShowSetTop(true);
        showSetTopRef.current = true;
      }
      if (container.scrollTop <= 0 && showSetTopRef.current) {
        setShowSetTop(false);
        showSetTopRef.current = false;
      }
    }
  };

  const setTop = () => {
    const container = document.querySelector(".mainContainerContent");
    if (container) {
      container.scrollTop = 0;
      setShowSetTop(false);
      showSetTopRef.current = false;
    }
  };

  useEffect(() => {
    const scrollContainer = document.getElementsByClassName(
      "mainContainerContent"
    )[0];
    scrollContainer.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    clearAll();

    opusId = searchParams.get("opusId");
    postRequest("/api/view", { opusId });
    getRequest("/api/opus", { opusId }).then((res) => {
      const { data } = res;
      setTitle(data?.title || "");
      canDownload.current = data.canDownload;
      setCreateTime(data?.publishTime || "2024-08-09 20:08:00");
      setWatched(data?.viewCnt || 0);
      setCollected(data?.collectCnt || 0);
      setPrice(data?.price / 100 || "未知");
      setImgs(data?.imgList || []);
      setAuthorAvatar(data?.authorAvatar || defaultAvatar);
      setHasCollected(data?.hasCollected || false);
      userId.current = data?.authorId || "0";
    });
    // 相关推荐
    postRequest("/api/home/recommend", { opusId }).then((res) => {
      setFooterImgs(res.data.list);
    });
    return () => {
      opusId = "";
      clearAll();
    };
  }, [searchParams]);

  const jumptoPersonal = () => {
    navigate(`/personal?userId=${userId.current}`);
  };

  const handleCollect = (e, flag, id) => {
    e.stopPropagation();
    if (!userInfo?.id) {
      message.error("未登录");
      setIsLoginOpen(true);
      return;
    }
    let tmpId = id ? id : opusId;
    if (flag) {
      postRequest("/api/collect", { opusId: tmpId }).then(() => {
        message.success(`收藏成功`);
        if (!id) {
          setHasCollected(true);
        } else {
          const tmpImages = JSON.parse(JSON.stringify(footerImgs));
          tmpImages.forEach((item) => {
            if (item.opusId === tmpId) {
              item.hasCollected = true;
            }
          });
          setFooterImgs(tmpImages);
        }
      });
    } else {
      postRequest("/api/collect/cancel", { opusId: tmpId }).then(() => {
        message.success(`取消收藏成功`);
        if (!id) {
          setHasCollected(false);
        } else {
          const tmpImages = JSON.parse(JSON.stringify(footerImgs));
          tmpImages.forEach((item) => {
            if (item.opusId === tmpId) {
              item.hasCollected = false;
            }
          });
          setFooterImgs(tmpImages);
        }
      });
    }
  };

  const handleDownload = () => {
    if (!userInfo?.id) {
      message.error("未登录");
      setIsLoginOpen(true);
      return;
    }
    if (!canDownload.current) {
      handleGetErweima();
    } else {
      getDownload();
    }
  };

  const getStatus = (data) => {
    getRequest("/api/order/state", { key: data }).then((res) => {
      const orderFailCode = ["-1002", "-1003", "-1004"];
      if (+res.code === 1) {
        message.success("支付成功，开始下载...");
        canDownload.current = true;
        handleCloseModal();
        getDownload();
        clearAll();
        return;
      } else if (res.code.toString() === "-1000") {
        // 代表一直未扫码（二维码中间不做处理）
        return;
      } else if (res.code.toString() === "-1001") {
        // 代表扫码了 二维码中间显示支付中
        setIsShowMask(true);
        setMaskStatus("paying");
        return;
      } else if (orderFailCode.includes(res.code.toString())) {
        // （二维码过期或订单失效，刷新）
        setIsShowMask(true);
        setMaskStatus("fail");
        clearAll();
        return;
      } else {
        message.error(res?.msg || "支付失败，请重新扫码进入");
        handleCloseModal();
      }
    });
  };

  const handleGetErweima = () => {
    setMaskStatus("");
    setIsShowMask(false);
    postRequest("/api/order/h5", { opusId }).then((res) => {
      const { data } = res;
      if (+res?.code === 1) {
        setErweima(data.code);
        setIsOpen(true);
        // 开始轮询获取支付结果
        const key = data.token;
        clearAll();
        timeout = setTimeout(() => {
          interval = setInterval(() => {
            getStatus(key);
          }, 500);
        }, 2000);
      } else {
        message.error(res?.msg || "获取二维码失败，请重试");
      }
    });
  };

  const getDownload = () => {
    const token = localStorage.getItem("token");
    setIsShowMaskForWindow(true);
    axios
      .get(`https://xiaoguotu.com/api/opus/download?opusId=${opusId}`, {
        responseType: "blob", // 设置响应类型为blob
        headers: {
          Authorization: `${token}`, // 添加Authorization头
          Accept: "application/zip", // 指定下载类型为ZIP
        },
      })
      .then((response) => {
        // 创建一个URL对象
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));

        // 创建一个链接元素
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", response.headers["x-filename"]); // 设置下载文件名

        // 将链接添加到DOM并点击
        document.body.appendChild(link);
        link.click();

        // 移除链接元素
        document.body.removeChild(link);
        setIsShowMaskForWindow(false);
      })
      .catch(() => {
        message.error("下载失败，请重试");
        setIsShowMaskForWindow(false);
      });
  };

  const handleCloseModal = () => {
    clearAll();
    setIsOpen(false);
  };

  const jumpToDetail = (id) => {
    navigate(`/detail?opusId=${id}`);
    const container = document.querySelector(".mainContainerContent");
    if (container) {
      container.scrollTop = 0; // 将滚动位置设置为顶部
      setShowSetTop(false);
      showSetTopRef.current = false;
    }
  };

  return (
    <div className="detailContainer">
      <div className="detailHeader">
        <div className="mainTitle">{title}</div>
        <div className="subTitle">
          <div className="createTime">
            <div className="createTimeLabel">发布时间：</div>
            <div className="createTimeVal">{createTime}</div>
          </div>
          <div className="watched">
            <div className="watchedIcon" />
            <div className="watchedVal">{watched}</div>
          </div>
          <div className="collected">
            <div className="collectedIcon" />
            <div className="collectedVal">{collected}</div>
          </div>
        </div>
      </div>

      <div className="rightNav">
        <div className="rightNavItem">
          <img
            src={authorAvatar}
            className="rightNavItemPic"
            onClick={jumptoPersonal}
          />
        </div>
        <div
          className="rightNavItem"
          onClick={(e) => handleCollect(e, !hasCollected)}
        >
          {hasCollected ? (
            <img className="rightNavItemIcon" src={collectYellow} />
          ) : (
            <img className="rightNavItemIcon" src={collectBlack} />
          )}
        </div>
        <div className="rightNavItem" onClick={handleDownload}>
          <img src={downloadBlack} className="rightNavItemIcon" />
        </div>
        <div className="rightNavItem">
          <img src={shareBlack} className="rightNavItemIcon3" />
        </div>
        {showSetTop ? (
          <div className="rightNavItem" onClick={setTop}>
            <img src={backToTop} className="rightNavItemIcon2" />
          </div>
        ) : null}
      </div>

      <div className="imgContainer">
        {imgs.map((item, index) => {
          return (
            <div className="imgItem" key={`images${index}`}>
              <img className="imgItemPic" src={item} />
            </div>
          );
        })}
      </div>

      <div className="noMorePic">
        <div className="splitLine" />
        <div className="noMorePicText">没有更多了</div>
        <div className="splitLine" />
      </div>

      <div className="detailFooter" id="targetItem">
        <div className="footerTitle">相关推荐</div>
        <div className="detailFooterImgContainer">
          {footerImgs.length
            ? footerImgs.map((item, index) => (
                <div
                  className="footerItem"
                  key={`footer${index}`}
                  onClick={() => jumpToDetail(item.opusId)}
                >
                  <div className="imgContainerFooter">
                    <img className="footerItemPic" src={item.cover} />

                    <div className="collectIcon1">
                      {item.hasCollected ? (
                        <img
                          className="collectIconPic"
                          src={collectYellow}
                          onClick={(e) => handleCollect(e, false, item.opusId)}
                        />
                      ) : (
                        <img
                          className="collectIconPic"
                          src={collectBlack}
                          onClick={(e) => handleCollect(e, true, item.opusId)}
                        />
                      )}
                    </div>
                  </div>

                  <div className="footerItemTitle">{item.title}</div>
                  <div className="footerItemSubTitle">
                    <div className="footerItemAuthor">{item.author}</div>
                    <img className="footerItemWatch" src={hasChecked} />
                    <div className="footerItemWatchVal">{item.viewCnt}</div>
                    <img className="footerItemCollect" src={collectBlack} />
                    <div className="footerItemCollectVal">
                      {item.collectCnt}
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>

      <Modal
        title={`下载素材：${title}`}
        open={isOpen}
        centered
        onCancel={handleCloseModal}
        footer={null}
        className="downloadModal"
      >
        <div className="line"></div>
        <div className="downloadModalContainer">
          <div className="flexContainer">
            <div className="leftPic">
              <img src={erweima} className="leftPicPic" />
              {isShowMask ? (
                <div className="leftPicInvalid">
                  {maskStatus === "paying" ? (
                    <div className="invalidText">支付中</div>
                  ) : null}
                  {maskStatus === "fail" ? (
                    <>
                      <div className="invalidText">订单失效</div>
                      <div className="invalidBtn" onClick={handleGetErweima}>
                        刷新
                      </div>
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className="rightTextContainer">
              <div className="rightLine1">
                <div className="label">实价：</div>
                <div className="price">￥</div>
                <div className="price priceCount">{price}</div>
              </div>

              <div className="rightLine2">
                <div className="label">扫码支付</div>
                <img className="rightIcon" src={weichatLogo} />
                <img className="rightIcon" src={zhifubao} />
              </div>
            </div>
          </div>
          <div className="bottomTips">
            温馨提示：已购买的素材30天内可免费重复下载哦~
          </div>
        </div>
      </Modal>

      <Login
        isOpen={isLoginOpen}
        handleCloseModal={() => setIsLoginOpen(false)}
      />
    </div>
  );
};

export default Detail;
