import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { message } from "antd";
import { useUser } from "../../context";
import defaultAvatar from "../../assets/defaultAvatar.png";
import { getRequest } from "../../service/index"; // 引入封装的请求
import "./index.less";

const AdminLayout = () => {
  const { userInfo } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const [avatar, setAvatar] = useState(defaultAvatar);
  const [userId, setUserId] = useState("");
  const [selectedVal, setSelectedVal] = useState("collect");

  useEffect(() => {
    if (location.pathname.includes("collect")) {
      setSelectedVal("collect");
    } else if (location.pathname.includes("download")) {
      setSelectedVal("download");
    } else if (location.pathname.includes("publish")) {
      setSelectedVal("publish");
    } else if (location.pathname.includes("upload")) {
      setSelectedVal("upload");
    } else if (location.pathname.includes("profit")) {
      setSelectedVal("profit");
    } else if (location.pathname.includes("personal")) {
      setSelectedVal("personal");
    } else if (location.pathname.includes("history")) {
      setSelectedVal("history");
    } else if (location.pathname.includes("setting")) {
      setSelectedVal("setting");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (userInfo?.avatar) {
      setAvatar(userInfo.avatar);
    }
    if (userInfo?.id) {
      setUserId(userInfo.id);
      getRequest("/api/user/token").then((res) => {
        if (res?.data) {
          localStorage.setItem("token", res.data);
        }
      });
    } else {
      setUserId("default_user_id");
    }
  }, [JSON.stringify(userInfo)]);

  const jumpTo = (address) => {
    if (address === "personal") {
      if (!userInfo?.id) {
        message.error("未登录");
        return;
      }
      window.open(`/personal`);
    } else {
      navigate(`/admin/${address}`);
      setSelectedVal(address);
    }
  };

  return (
    <div className="adminLayout">
      {/* 左侧导航栏 */}
      <div className="sidebar">
        <div className="sidebarTop">
          <img src={avatar} className="headerLogo" />
          <div className="userId">{userId}</div>
        </div>

        <div className="sidebarNav">
          <div
            className={`collect ${
              selectedVal === "collect" ? "collected" : ""
            }`}
            onClick={() => jumpTo("collect")}
          >
            <div className="linkItemIcon" />
            <div className="linkItemText">我的收藏</div>
          </div>
          <div
            className={`download ${
              selectedVal === "download" ? "downloaded" : ""
            }`}
            onClick={() => jumpTo("download")}
          >
            <div className="linkItemIcon" />
            <div className="linkItemText">我的下载</div>
          </div>
          <div
            className={`publish ${
              selectedVal === "publish" ? "published" : ""
            }`}
            onClick={() => jumpTo("publish")}
          >
            <div className="linkItemIcon" />
            <div className="linkItemText">发布作品</div>
          </div>
          <div
            className={`upload ${selectedVal === "upload" ? "uploaded" : ""}`}
            onClick={() => jumpTo("upload")}
          >
            <div className="linkItemIcon" />
            <div className="linkItemText">上传记录</div>
          </div>
          <div
            className={`profit ${selectedVal === "profit" ? "profited" : ""}`}
            onClick={() => jumpTo("profit")}
          >
            <div className="linkItemIcon" />
            <div className="linkItemText">收益中心</div>
          </div>
          <div
            className={`personal ${
              selectedVal === "personal" ? "personaled" : ""
            }`}
            onClick={() => jumpTo("personal")}
          >
            <div className="linkItemIcon" />
            <div className="linkItemText">我的主页</div>
          </div>
          <div
            className={`history ${
              selectedVal === "history" ? "historyed" : ""
            }`}
            onClick={() => jumpTo("history")}
          >
            <div className="linkItemIcon" />
            <div className="linkItemText">浏览历史</div>
          </div>
          <div
            className={`setting ${
              selectedVal === "setting" ? "settinged" : ""
            }`}
            onClick={() => jumpTo("setting")}
          >
            <div className="linkItemIcon" />
            <div className="linkItemText">账号设置</div>
          </div>
        </div>
      </div>

      {/* 右侧内容区域 */}
      <div className="content">
        <Outlet /> {/* 渲染子路由内容 */}
      </div>
    </div>
  );
};

export default AdminLayout;
