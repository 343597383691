import React, { useEffect, useState } from "react";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../service/index"; // 引入
import "./index.less";

const History = () => {
  const navigate = useNavigate();

  const [images, setImages] = useState([]); // 存储图片数据
  const [total, setTotal] = useState(100);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    fetchImages(0);
  }, []);

  // 模拟获取图片数据的函数
  const fetchImages = async (page) => {
    getRequest("/api/view", { page, size: 15 }).then((res) => {
      setTotal(res.data.total);
      setImages(res.data.list || []);
    });
  };

  const onPageChange = (page) => {
    setPageNum(page);
    fetchImages(page - 1);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>上一页</a>;
    }
    if (type === "next") {
      return <a>下一页</a>;
    }
    return originalElement;
  };

  const jumpToDetail = (id) => {
    navigate(`/detail?opusId=${id}`);
  };

  return (
    <div className="historyMain">
      <div className="historyPicContainer">
        {images.map((image, index) => (
          <div
            key={index}
            className="image-grid"
            onClick={() => jumpToDetail(image.opusId)}
          >
            <img src={image.cover} />
            <div className="picTitle">{image.title}</div>
          </div>
        ))}
      </div>

      {total > 40 ? (
        <Pagination
          className="paginationContainer"
          total={total}
          current={pageNum}
          itemRender={itemRender}
          showSizeChanger={false}
          onChange={onPageChange}
        />
      ) : null}
    </div>
  );
};

export default History;
