import React, { useEffect, useState, useRef } from "react";
import { Input, Dropdown, Menu, message, Divider } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../../context";
import Login from "../Login";
import logo from "../../assets/logo.png";
import foot from "../../assets/foot.png";
import collect from "../../assets/collect.png";
import search from "../../assets/search.png";
import upload from "../../assets/upload.png";
import defaultAvatar from "../../assets/defaultAvatar.png";
import "./index.less";

const Header = () => {
  const { userInfo, fetchUserInfo } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState("false");
  const [isModalOpen, setIsModalOpen] = useState(false); // 控制 Modal 是否显示
  const [userAvatar, setUserAvatar] = useState();
  const [inputVal, setInputVal] = useState("");
  const requestInterval = useRef(null);

  useEffect(() => {
    setIsLogin(localStorage?.getItem("login") || "false");

    return () => {
      if (requestInterval.current) {
        clearInterval(requestInterval.current);
        requestInterval.current = null;
      }
    };
  }, []);

  useEffect(() => {
    fetchUserInfo();
    userInfo?.avatar
      ? setUserAvatar(userInfo.avatar)
      : setUserAvatar(defaultAvatar);
    if (!userInfo) {
      setIsLogin("false");
    } else {
      setIsLogin("true");
    }
  }, [JSON.stringify(userInfo), location.pathname]);

  const handleLogin = () => {
    setIsModalOpen(true);
  };

  const handleSearch = () => {
    navigate(`/home?keyWord=${inputVal}`);
  };

  const jumpToHome = () => {
    setInputVal("");
    navigate(`/home`);
    const container = document.querySelector(".mainContainerContent");
    if (container) {
      container.scrollTop = 0; // 将滚动位置设置为顶部
    }
  };

  const jumpToAdmin = () => {
    setInputVal("");
    if (location.pathname.includes("admin")) {
      return;
    }
    navigate(`/admin/collect`);
  };

  const jumoToHistory = () => {
    if (isLogin === "false") {
      message.error("请登陆后查看");
      return;
    }
    setInputVal("");
    if (location.pathname.includes("history")) {
      return;
    }
    navigate(`/admin/history`);
  };

  const jumoToCollect = () => {
    if (isLogin === "false") {
      message.error("请登陆后查看");
      return;
    }
    setInputVal("");
    if (location.pathname.includes("collect")) {
      return;
    }
    navigate(`/admin/collect`);
  };

  const jumoToPublish = () => {
    if (isLogin === "false") {
      message.error("请登陆后查看");
      return;
    }
    setInputVal("");
    if (location.pathname.includes("publish")) {
      return;
    }
    navigate(`/admin/publish`);
  };

  const logout = () => {
    localStorage.removeItem("token");
    if (location.pathname.includes("admin")) {
      navigate("/home");
    } else {
      window.location.reload();
    }
  };

  const menuItems = [
    {
      key: "6",
      className: "custom-menu-item",
      label: "我的主页",
      onClick: () => window.open("/personal", "_blank"),
    },
    {
      key: "1",
      className: "custom-menu-item",
      label: "我的收藏",
      onClick: () => window.open("/admin/collect", "_blank"),
    },
    {
      key: "2",
      className: "custom-menu-item",
      label: "我的下载",
      onClick: () => window.open("/admin/download", "_blank"),
    },
    {
      key: "4",
      className: "custom-menu-item",
      label: "上传记录",
      onClick: () => window.open("/admin/upload", "_blank"),
    },
    {
      key: "7",
      className: "custom-menu-item",
      label: "浏览历史",
      onClick: () => window.open("/admin/history", "_blank"),
    },
    {
      key: "8",
      label: "账号设置",
      className: "custom-menu-item",
      onClick: () => window.open("/admin/setting", "_blank"),
    },
    {
      key: "divider",
      type: "divider", // 使用 divider 来插入分割线
    },
    {
      key: "10",
      label: "退出登录",
      className: "custom-menu-item",
      onClick: logout,
    },
  ];

  return (
    <div className="headerMain">
      <div className="headerLeft" onClick={jumpToHome}>
        <img src={logo} className="headerLogo" />
        <div className="headerTitle">效果图网</div>
      </div>
      <div className="headerCenter">
        <Input
          className="headerCenterInput"
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
          onPressEnter={handleSearch}
          placeholder="请输入关键词，搜索效果图"
        />
        <img className="headerCenterIcon" onClick={handleSearch} src={search} />
      </div>
      <div className="headerRight">
        <div className="headerRightBtn" onClick={jumoToHistory}>
          <img className="headerRightIcon" src={foot} />
          <div className="headerRightText">足迹</div>
        </div>
        <div className="headerRightBtn" onClick={jumoToCollect}>
          <img className="headerRightIcon" src={collect} />
          <div className="headerRightText">收藏</div>
        </div>
        <div className="headerRightBtn" onClick={jumoToPublish}>
          <img className="headerRightIcon" src={upload} />
          <div className="headerRightText">发布作品</div>
        </div>
        <div className="headerRightEnd">
          {isLogin === "false" ? (
            <div onClick={handleLogin} className="headerRightUnlogin">
              登录 | 注册
            </div>
          ) : (
            <Dropdown
              menu={{
                items: menuItems,
              }}
              trigger={["hover"]}
              overlayClassName="custom-dropdown-menu"
              placement="bottomCenter" 
            >
              <img
                onClick={jumpToAdmin}
                className="headerRightLoginIcon"
                src={userAvatar}
              />
            </Dropdown>
          )}
        </div>
      </div>

      <Login
        isOpen={isModalOpen}
        handleCloseModal={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default Header;
