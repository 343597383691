import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getRequest, postRequest } from "../../service/index"; // 引入封装的请求
import { useUser } from "../../context";
import defaultAvatar from "../../assets/defaultAvatar.png";
import kefu from "../../assets/kefu.jpg";
import "./index.less";

let userId = "";

const Personal = () => {
  const [searchParams] = useSearchParams();
  const { userInfo } = useUser();
  const navigate = useNavigate();

  const [avatar, setAvatar] = useState();
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(true); // 加载状态
  const [images, setImages] = useState([]); // 存储图片数据
  const [project, setProject] = useState(14);
  const [renqi, setRenqi] = useState("");
  const [isHasMore, setIsHasMore] = useState(false); // 是否还有数据来分页
  const [showSetTop, setShowSetTop] = useState(false);
  const showSetTopRef = useRef(false);
  const [isHovered, setIsHovered] = useState(false);

  const observer = useRef(null); // 用于观察滚动到底部加载更多
  const lastImageRef = useRef();
  const imageRequestParam = useRef({ nextId: 0 });

  useEffect(() => {
    userId = searchParams?.get("userId") || "0";
    const scrollContainer = document.getElementsByClassName(
      "mainContainerContent"
    )[0];
    scrollContainer.addEventListener("scroll", handleScroll);
    if (userInfo?.id) {
      getRequest("/api/user/token").then((res) => {
        localStorage.setItem("token", res.data);
      });
    }

    let url = "/api/user/home_info";
    if (userId) {
      url = `/api/user/home_info?userId=${userId}`;
    }
    getRequest(url).then((res) => {
      const { data } = res;
      setUserName(data?.nickname || "default_user_id");
      setProject(data?.opusCnt || 0);
      setRenqi(data?.popularity || 0);
      setAvatar(data?.avatar || defaultAvatar);
    });
    imageRequestParam.current.nextId = 0;
    fetchImages(0);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const container = document.querySelector(".mainContainerContent");
    if (container) {
      // 当滚动超过50px时触发逻辑
      if (container.scrollTop > 50 && !showSetTopRef.current) {
        setShowSetTop(true);
        showSetTopRef.current = true;
      }
      if (container.scrollTop <= 0 && showSetTopRef.current) {
        setShowSetTop(false);
        showSetTopRef.current = false;
      }
    }
  };

  const fetchImages = async () => {
    userId = searchParams?.get("userId") || "0";
    if (imageRequestParam.current.nextId === -1) {
      setIsHasMore(false);
      return;
    }
    setLoading(true);
    const param = {
      nextId: imageRequestParam.current.nextId,
    };
    if (userId) {
      param.userId = userId;
    }
    postRequest("/api/user/opus", param).then((res) => {
      setLoading(false);
      setImages([...images, ...res.data.list]);
      if (res.data.nextId == -1 || imageRequestParam.current.nextId === -1) {
        imageRequestParam.current.nextId = -1;
        setIsHasMore(false);
      } else {
        imageRequestParam.current.nextId = res.data.nextId;
        setIsHasMore(true);
      }
    });
  };

  // 实现滚动到底部时加载更多图片
  useEffect(() => {
    if (loading || !isHasMore) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchImages(); // 页面到底部时加载下一页数据
      }
    });

    if (lastImageRef.current) {
      observer.current.observe(lastImageRef.current);
    }
  }, [loading, isHasMore]);

  const jumpToDetail = (id) => {
    navigate(`/detail?opusId=${id}`);
  };

  const setTop = () => {
    const container = document.querySelector(".mainContainerContent");
    if (container) {
      container.scrollTop = 0; // 将滚动位置设置为顶部
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="personalMain">
      <div className="rightNavigator">
        <div
          className="rightNavigatorItem1"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="connectUs" />
        </div>
        {showSetTop ? (
          <div className="rightNavigatorItem" onClick={setTop}>
            <div className="rightNavItemIcon2"></div>
          </div>
        ) : null}
      </div>

      <div className={`diytooltip ${isHovered ? "tipvisible" : ""}`}>
        <div className="tipTitle">客服咨询</div>
        <div className="tipSubTitle">周一至周六：08:30-18:00</div>
        <img className="tipErweima" src={kefu} />
        <div className="tipFooter">扫描二维码添加客服微信</div>
      </div>

      <div className="personHeader">
        <div className="headerLeft">
          <img src={avatar} className="avatar" />
          <div className="userName">{userName}</div>
        </div>
        <div className="headerRight">
          <div className="leftContainer">
            <div className="label">作品</div>
            <div>{project}</div>
          </div>
          <div className="splitLine"></div>
          <div className="rightContainer">
            <div className="label">人气</div>
            <div>{renqi}</div>
          </div>
        </div>
      </div>

      <div className="personalContainer">
        <div className="picContainer">
          {images.map((image, index) => (
            <div
              key={`personal${index}`}
              className="image-grid"
              onClick={() => jumpToDetail(image.opusId)}
            >
              <img src={image.cover} />
              <div className="picTitle">{image.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Personal;
