import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./index.less";

const items = [
  { id: 1, label: "关于我们" },
  { id: 2, label: "用户协议" },
];

const AboutUs = () => {
  const [searchParams] = useSearchParams();

  const [selected, setSelected] = useState(1);
  const [header, setHeader] = useState("关于我们");

  const handleClick = (item) => {
    setSelected(item.id);
    setHeader(item.label);
  };

  useEffect(() => {
    const tab = searchParams?.get("tab") || "";
    setSelected(parseInt(tab));
    if (!tab) {
      setSelected(1);
    }
  }, [searchParams.get("tab")]);

  return (
    <div className="aboutUsContainer">
      <div className="leftNav">
        {items.map((item) => (
          <div
            key={`aboutUs${item.id}`}
            className={`navItem ${selected === item.id ? "selected" : ""}`}
            onClick={() => handleClick(item)}
          >
            {item.label}
          </div>
        ))}
      </div>
      <div className="mainContainer">
        <div className="mainHeader">{header}</div>
        <div className="mainContent">
          {selected == 1 ? (
            <div>
              <div className="text">
                效果图网（xiaoguotu.com）隶属无锡图新奇网络科技有限公司。效果图网
                -
                是一个装修效果图交流交易平台，提供室内外工装家装、美陈等优质高清效果图，设计师朋友们可在本站付费下载想要空间的高清效果原图，同时绘图员们可上传作品享永久收益。
              </div>
              <div className="header1">联系方式</div>
              <div className="text">客服QQ：716437392</div>
              <div className="text">客服热线：13666445586</div>
              <div className="text">公司地址：江苏无锡</div>
            </div>
          ) : null}

          {selected == 2 ? (
            <div>
              <div className="text">欢迎您使用效果图网</div>
              <div className="text">
                本协议系由您（或称“用户”）与效果图网（或称“我们”或“平台”）之间就关于使用效果图网各项服务而签订
              </div>
              <div className="text">
                在使用效果图网平台服务之前，请您务必审慎阅读、充分理解本协议（如果您未满16周岁，或已满16周岁未满18周岁且不能以自己的劳动收入作为主要收入来源的，请在法定监护人的陪同下阅读本协议）各项条款，特别是限制或免除责任条款、隐私保护政策、账号规则、法律适用和争议解决条款（包括管辖条款），以及其他以加粗加黑和/或加下划线等显示形式提示您注意的重要条款，请务必重点查阅。
              </div>
              <div className="text">
                您通过勾选“同意《效果图网用户协议》”，并点击“确定”，即表示您正式与效果图网的运营公司（即无锡图新奇网络科技股份有限公司）签订本协议，同意遵守本协议的全部规定。若您不同意本协议，则您有充分且完全的权利退出使用平台服务（但这并不妨碍您浏览平台中的信息）。如果您对本协议有任何的疑问、投诉、意见和建议，欢迎您通过本协议所附联系方式与我们沟通反馈，我们十分乐意为您解答。
              </div>
              <div className="header1">一、基础约定</div>
              <div className="text">
                1.协议范围：互联网服务以及产品频繁迭代更新等行业特点，为了更全面地界定您与平台之间的权利义务，本协议包括我们根据法律法规规定以及本协议制定的其他政策、规则、公告声明等（除非特有所指，合称为“本协议”），您也应当加以遵守。
              </div>
              <div className="text">
                2.服务范围：我们可能通过不断丰富的功能界面向您提供本协议项下的平台服务，包括但不限于移动应用程序（“APP”）、PC端网站、小程序以及其他形式。具体以我们实时发布的服务功能界面范围为准。
              </div>
              <div className="text">
                3.修改与更新：我们有权遵照法律法规规定，在必要时修改本协议（包括适时制定并发布其他政策、规则、公告声明），更新后的协议条款将代替原来的协议，平台将通过网页公告、电子邮件、系统通知、平台管理账号主动联系、私信、手机短信或常规的信件传送等方式向您提示修改的内容。本协议修改后，如果您继续使用平台服务的，即视为您已接受修改后的协议。如果您不接受修改后的协议，则您有权利停止使用平台服务。
              </div>
              <div className="header1">二、平台服务</div>
              <div className="text">
                1.效果图网是由无锡图新奇网络科技股份有限公司运营的装修效果图分享交易平台，设计师通过效果图网上传下载原创作品，供效果图网会员浏览、下载以及为非商业目的使用。
              </div>
              <div className="text">
                2.效果图网网页地址：【xiaoguotu.com】。
              </div>
              <div className="text">
                3.您同意并知悉，平台中的具体内容、功能和形式由平台根据实际情况按实际可见的状态提供，我们有权自行确定平台服务的具体内容、功能和形式，有权自行决定增加、变更、中断和停止平台具体的内容、功能和形式。具体以平台实时呈现的服务内容、功能和形式为准。
              </div>
              <div className="header1">三、会员注册及账号管理</div>
              <div className="text">
                1.普通会员：注册成为普通会员后，您即可以免费获得网站提供的相关服务，包括但不限于浏览、收藏图片等。普通会员可下载素材的范围、质量、数量等由平台根据网站运营实际情况综合决定并随时调整。平台可以根据网站运营的实际情况，随时终止为普通会员免费提供的相关服务，且不因此承担任何责任。
              </div>
              <div className="text">
                2.您在注册或使用平台服务时可能需要提供一些必要的信息。您须保证所填写及所提供的资料真实、准确、完整，否则可能无法使用平台服务，或在使用过程中受到限制，甚至影响您是否能够使用平台特定功能。对于因您提供的信息不真实、不准确或不完整导致的责任和损失由您承担。
              </div>
              <div className="text">
                3.如您按照平台页面提示，完成相应注册程序，阅读并同意本协议后，方可成为平台会员。如您想要升级成为VIP会员，享受更海量更优质的资源，还应根据平台网站页面提示，进行相应数额的充值。
              </div>
              <div className="text">
                4.用户一经注册，即可以在平台中进行使用。在使用平台服务账号登录并使用服务时，应遵守本协议的约定。如果您需要注销您的账号，您有权通过平台公布的方式、程序进行，我们将在核实您的身份并清理账号资产（例如已充值或已获得的虚拟资产等）及纠纷争议后，为您提供账号注销服务。在注销账号之后本协议即终止，我们将停止为您提供任何服务。
                <div>（1）严重违反本协议规定的行为；</div>
                <div>（2）平台认为符合回收的其他情况。</div>
              </div>
              <div className="text">
                5.您有责任自行负责保管账号和密码等信息，否则因该等事项引发的法律责任由用户自行承担。凡使用平台服务登录账号和登录凭证的行为，平台服务视为您本人的操作，操作所产生的电子信息记录均为平台服务用户行为的有效凭据。
              </div>
              <div className="header1">四、用户行为规范</div>
              <div className="text">
                1.您保证合理地使用平台服务，并接受本协议和我们适时制定并发布的其他政策、规则、公告声明。
              </div>
              <div className="text">
                2.行为禁止：您可在本协议约定的范围内使用平台及服务，您不得利用平台从事以下行为，否则，平台有权不事先通知您即删除违法、侵权的内容：
                <div>（1）超出授权或恶意使用平台服务；</div>
                <div>
                  （2）利用平台发表、传送、传播、储存危害国家安全、国家统一、社会稳定的内容，或侮辱诽谤、色情、暴力、引起他人不安及任何违反国家法律法规政策的内容，或者设置含有上述内容的网名、角色名，发布、传送、传播违法广告信息、营销信息及垃圾信息等；
                </div>
                <div>
                  （3）利用平台侵害他人知识产权、肖像权、隐私权、名誉权、个人信息等合法权利或权益；
                </div>
                <div>（4）进行任何危害计算机网络安全的行为；</div>
                <div>
                  （5）对平台进行反向工程、反向编译或反向汇编或以其他方式企图发现平台的源代码和算法，未经许可修改、禁用软件的任何功能或创建基于软件的衍生作品。去除平台或文档上的任何所有权声明或标签，或将其他软件与平台合并；
                </div>
                <div>
                  （6）从事其他法律法规、政策及公序良俗、社会公德禁止的行为以及侵犯其他个人、公司、社会团体、组织的合法权益的行为。
                </div>
              </div>
              <div className="text">
                3.信息内容规范：为了营造良好网络生态，保障公民、法人和其他组织的合法权益，维护国家安全和公共利益，我们将根据《中华人民共和国国家安全法》《中华人民共和国网络安全法》《互联网信息服务管理办法》等法律、行政法规，自觉践行社会主义核心价值观，营造清朗的网络空间，并开展弘扬正能量、处置违法和不良信息等相关活动。
              </div>
              <div className="text">
                4.未经我们书面许可，您不得自行或授权、允许、协助任何第三人进行如下行为：
                <div>
                  （1）复制、读取、采用平台所展示的素材、案例等内容，将相关内容拷贝、传输给他人，或用于任何形式的商业用途；
                </div>
                <div>
                  （2）擅自编辑、整理、编排平台所展示的素材、案例等内容，修改素材的属性信息，或在平台的源页面以外的渠道进行展示；
                </div>
                <div>
                  （3）实施侵犯素材相关知识产权的行为，包括但不限于未经许可将平台网站所展示的素材、案例进行信息网络传播，上传至其他图库、网盘等网络平台，以及使用P2P软件向他人传输等；
                </div>
                <div>
                  （4）恶意下载，包括但不限于短时间内下载不合理数量素材的行为；
                </div>
                <div>（5）违反法律法规或者侵害他人民事权益的行为；</div>
              </div>
              <div className="text">
                5.平台仅为个人学习、研究或者欣赏，学校课堂教学等非商业目的进行浏览、下载、使用的相关素材、案例的用途。如您为商业经营的目的下载、使用相应素材和案例，应获得权利人的事先许可。如果您未经许可，为商业经营的目的浏览、下载、使用相关素材、案例，侵犯他人权利，应当根据法律规定向他人承担相应责任，平台不承担任何责任。
              </div>
              <div className="header1">五、个人信息保护与隐私政策</div>
              <div className="text">
                1.尊重用户隐私并保护您的个人信息安全是我们的一贯态度，平台将会采取合理的措施保护您的个人信息与隐私。我们承诺，除非获得用户同意，平台不会收集、使用其提供服务所必需以外的用户个人信息或者将信息用于提供服务之外的目的。
              </div>
              <div className="text">
                2.安全保护：我们将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息及隐私安全，以免遭受未经授权的访问、使用或披露。
              </div>
              <div className="text">
                3.信息使用与披露：我们有权在遵守法律法规的前提下，以明示的方式获取、使用、储存和分享您的个人信息。我们不会在未经您授权时，公开、编辑或透露您的个人信息及您保存在我们的非公开内容。您同意并保证：平台有权根据我们制定的个人信息保护规则收集使用您的相关信息。
              </div>
              <div className="text">
                4.为了给您提供更优质、更便捷、更个性化的服务，平台的关联方、合作方（如微信、QQ）会依据法律的规定或与您的约定或征求您的同意的前提下，向平台分享您的个人信息。例如，您使用微信号登录时，需要核实您的身份或绑定您的账户信息，经过您的授权将相关账号关联到平台会员账号。
              </div>
              <div className="text">
                5.您同意，我们有权通过cookie等技术收集您的使用、行为信息，并在经过数据脱敏使之不再指向、关联到您个人的身份信息时，自由使用脱敏后的纯商业数据。当然，您也可根据自己的偏好删除Cookie，但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。目前删除Cookie的一般路径是浏览器：“设置－清除数据”，或者将手机系统还原/清除。
              </div>
              <div className="header1">六、信息和广告推送</div>
              <div className="text">
                1.您同意在接受我们提供服务的同时，允许我们在遵守法律法规的前提下自行或由第三方广告商向您发送、展示广告、推广或宣传信息（包括商业与非商业信息）。如您对发送、推荐的广告或信息不感兴趣，您可以基于我们提供的相关技术选项，控制系统向您展示或不展示/减少展示相关类型的广告或信息。
              </div>
              <div className="header1">七、知识产权等权益</div>
              <div className="text">
                1.平台上的全部内容（包括但不限于图片、案例等）是平台或相关权利人的财产，受著作权、商标、外观设计专利、企业名称、域名等知识产权保护。如您需为商业经营的目的使用平台网站上的相关内容，应主动联系权利人，获得权利人授权许可，否则，未经平台或相关权利人书面许可，任何人不得使用或许可第三方使用。
              </div>
              <div className="text">
                2.您知晓并同意：对于用户经由平台上传的电子素材，平台不保证其合法性、正当性、准确性、完整性以及品质。用户在接受平台服务后，在任何情况下，平台均不对任何电子素材承担任何责任，包括但不限于任何内容发生任何错误或纰漏以及衍生的任何损失或损害。如您认为相关电子素材损害您合法权益的，应当向对应上传用户主张，但是平台有权（但无义务）采取包括但不限于删除、屏蔽、修改相关链接或内容等处理方式。
              </div>
              <div className="text">
                3.关于投诉或举报。若您在使用平台过程中，不慎受到合法权益的侵犯，您有权通知我们采取必要措施进行处置。若您在使用平台过程中，发现存在违法违规或违反本服务相关规则的情况，您也有权向我们发起举报，我们经核实，如确认系侵权行为，我们亦会及时采取必要措施（包括但不限于删除、屏蔽、断开、修改相关链接或内容等）进行处置。
              </div>
              <div className="text">
                4.内容维权授权。在法律法规允许的范围内，您同意并授权我们就侵犯您合法权益的行为（包括但不限于私自复制、使用、编辑、抄袭等行为）采取任何形式的法律行动，包括但不限于投诉、诉讼等必要的维权措施。
              </div>
              <div className="header1">八、免责声明</div>
              <div className="text">
                1.我们对于您自平台而获得的所有他人、第三方的信息、内容或者广告宣传等任何资讯（以下统称“信息”），除法律明确规定外，不保证真实、准确和完整性，不承诺本平台网站或本平台网站的任何内容、服务或功能无任何错误或不中断。如果任何单位或者个人通过上述“信息”而进行任何行为，您须自行甄别真伪和谨慎预防风险。您在接受平台及服务时，有可能会接触到令人不快、不适当或令人厌恶的内容，在任何情况下，我们均不对任何此等内容承担任何责任。无论何种原因，我们不对任何非与我们直接发生的交易或行为承担任何直接、间接、附带或衍生的损失和责任。
              </div>
              <div className="text">
                2.鉴于互联网服务的特殊性，您理解并同意我们在以下情况下无需对您所遭受的损失（包括但不限于财产、收益、数据资料等方面的损失或其他无形损失）承担责任：
                <div>
                  （1）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素导致平台及服务障碍不能正常运作；
                </div>
                <div>
                  （2）由于计算机病毒、木马、其他恶意程序、黑客攻击、电信部门及网络运营公司技术调整或故障、系统维护等意外原因而造成平台及服务中断或延迟。
                </div>
              </div>
              <div className="header1">九、违约责任</div>
              <div className="text">
                1.如果我们发现、收到他人投诉或举报您违反本协议约定或违反法律法规和国家有关规定的，我们有权采取警示、冻结、终止/中止/限制使用账号等功能、解除或终止本协议，或提出损害赔偿等措施。
              </div>
              <div className="text">
                2.若您的行为给我们造成损失的（包括但不限于直接损失、名誉损失、第三方的罚款、索赔，为维权而支付的合理费用包括但不限于律师费、诉讼费、保全费、保全保险费、鉴定费、评估费、公告费等），我们有权全额向您追偿，如您在平台中有保证金、虚拟货币等财产或优惠券等虚拟权益的，我们有权冻结。
              </div>
              <div className="text">
                3.本协议终止后，除法律有明确规定外，平台无义务向您或您指定的第三方披露您账户中的任何信息。本协议终止后，平台仍享有下列权利：根据法律规定，继续保存您留存于平台的各类信息；对于您过往的违约行为，平台仍可依据本协议向您追究违约责任。
              </div>
              <div className="header1">十、法律适用与管辖</div>
              <div className="text">
                1.本协议的订立、执行、解释及争议的解决均适用中华人民共和国法律。
              </div>
              <div className="text">
                2.如双方就平台服务协议内容或其履行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向狒狒灵感的运营公司（即广东图居网络科技股份有限公司）所在地人民法院提起诉讼。
              </div>
              <div className="header1">十一、联系我们</div>
              <div className="text">
                1.如果您有任何的疑问、投诉、意见和建议，欢迎您与我们沟通反馈。我们的联系方式见下：
              </div>
              <div className="textHighlight">客服热线：【13666445586】</div>
              <div className="textHighlight">
                客服邮箱：【716437392@qq.com】
              </div>
            </div>
          ) : null}

          {/* {selected == 3 ? (
            <div className="tab3">
              <div className="text">
                <div>Q：下载的图片保存位置？</div>
                <div>
                  A：不同的浏览器保存路径不同，可打开浏览器设置，找到下载设置查看/更改保存路径。
                </div>
              </div>
              <div className="text">
                <div>Q：在狒狒灵感下载的图片能否用于商用？</div>
                <div>
                  A：不能。在狒狒灵感下载的内容仅供个人学习使用，请勿商用。
                </div>
              </div>
              <div className="text">
                <div>Q：手机收不到验证码？</div>
                <div>1. 手机欠费。请检查一下手机是否可以正常接收短信。</div>
                <div>
                  2.
                  由于运营商信号原因，导致接收短信有延迟，您可以多等一两分钟看看。
                </div>
              </div>
              <div className="text">
                <div>Q：支持对公打款吗？</div>
                <div>
                  A：可以。如您需要使用对公打款支付，可点击下方“在线客服”，在线客服将引导您完成付款。
                </div>
              </div>
            </div>
          ) : null}

          {selected == 4 ? (
            <div className="tab4">
              <div className="text">
                <div>1.冒充原创</div>
                <div>①在发布界面，将他人作品当作个人原创作品进行上传。</div>
                <div>
                  ②在发布界面，选择分享采集来上传他人作品并在正文中注释说明来源的情况除外。
                </div>
              </div>
              <div className="text">
                <div>2.重复上传</div>
                <div>
                  ①用户重复上传个人作品或平台已存在的作品，平台有权将作品驳回。
                </div>
              </div>
              <div className="text">
                <div>3.恶意上传</div>
                <div>①多次重复上传审核不通过的作品。</div>
                <div>②多次上传劣质或含违规信息的作品。</div>
                <div>③将同一个案例图片拆分为多个作品进行上传。</div>
                <div>④将多个案例的图片拼凑成一个新案例来进行上传。</div>
              </div>
              <div className="text">
                <div>4.违规推广</div>
                <div>①利用个人主页上的联系方式来对他人进行恶意骚扰或营销。</div>
                <div>②在案例正文或图片中发布各类营销信息/个人联系方式。</div>
              </div>
              <div className="text">
                <div>5.恶意刷量</div>
                <div>①通过刷案例浏览/收藏/下载等行为来提升案例排序的行为。</div>
              </div>
            </div>
          ) : null} */}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
