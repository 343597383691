import React, { useState, useRef } from "react";
import { Input, Modal, message, Button } from "antd";
import axios from "axios";
import phoneLogin from "../../assets/phoneLogin.png";
import weichatLogo from "../../assets/weichatLogo.png";
import weichat from "../../assets/weichat.png";
import { getRequest, postRequest } from "../../service/index"; // 引入封装的请求
import "./index.less";

const phoneRegex = /^[1]([3-9])[0-9]{9}$/;
let interval;
let countNum = 60;

const Login = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loginType, setLoginType] = useState("phone");
  const [erweima, setErweima] = useState("");
  const [erweimaInvalid, setErweimaInvalid] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [count, setCount] = useState(countNum);
  const requestInterval = useRef(null);

  // 关闭 Modal
  const handleClose = () => {
    if (interval) {
      clearInterval(interval);
      interval = null;
    }
    setLoginType("phone");
    setPassword("");
    setPhoneNumber("");
    setShowCode(false);
    setCode("");
    setIsLoading(false);
    setIsCodeSent(false);
    if (requestInterval.current) {
      clearInterval(requestInterval.current);
      requestInterval.current = null;
    }
    props.handleCloseModal();
  };

  const sendCode = () => {
    if (!phoneNumber) {
      message.error("请输入手机号");
      return;
    }

    if (!phoneRegex.test(phoneNumber)) {
      message.error("请输入有效的手机号");
      return;
    }

    postRequest("/api/captcha/sms", { phone: phoneNumber }).then((res) => {
      if (res?.code && +res.code === 1) {
        message.success("短信验证码已发送，请注意查收");
      } else {
        message.error(res?.msg || "短信验证码发送失败，请重试");
      }
      setIsLoading(true);
      setIsCodeSent(true);
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
      interval = setInterval(() => {
        if (countNum > 1) {
          countNum--;
          setCount(countNum);
        } else {
          clearInterval(interval);
          interval = null;
          setIsLoading(false);
          countNum = 60;
          setCount(countNum);
        }
      }, 1000);
    });
  };

  const getErweima = () => {
    getRequest("/api/wechat/ticket").then((res) => {
      if (res?.data) {
        axios
          .get(
            `https://xiaoguotu.com/api/wechat/qrcode?ticket=${res.data}`,
            {
              responseType: "blob", // 设置响应类型为 blob
              headers: {
                "Content-Type": "image/jpg",
              },
            }
          )
          .then((subRes) => {
            setErweimaInvalid(false);
            let data = URL.createObjectURL(subRes.data);
            setErweima(data);
            if (!requestInterval.current) {
              requestInterval.current = setInterval(() => {
                getRequest("/api/wechat/login", { ticket: res?.data }).then(
                  (finalRes) => {
                    if (finalRes.code == 1) {
                      handleClose();
                      localStorage.setItem("token", finalRes.data);
                      window.location.reload();
                    } else if (finalRes.code == -107) {
                      // 二维码失效
                      setErweimaInvalid(true);
                      clearInterval(requestInterval.current);
                      requestInterval.current = null;
                    }
                  }
                );
              }, 3000);
            }
          });
      }
    });
  };

  const refreshErweima = () => {
    clearInterval(requestInterval.current);
    requestInterval.current = null;
    getErweima();
  };

  const changeLoginType = (type) => {
    if (type === "weichat") {
      getErweima();
    }
    setShowCode(false);
    setLoginType(type);
    setPassword("");
    setShowCode(false);
  };

  const handlePhoneLogin = () => {
    if (!phoneNumber?.toString()?.length || !password?.toString()?.length) {
      message.error("请输入手机号或密码");
      return;
    }
    if (showCode && !code?.toString()?.length) {
      message.error("请输入验证码");
      return;
    }

    let url = "/api/user/login";
    let params = {
      phone: phoneNumber,
      password: password,
    };
    if (showCode) {
      url = "/api/user/register";
      params = {
        phone: phoneNumber,
        password: password,
        verification: code,
      };
    } else {
      url = "/api/user/login";
      params = {
        phone: phoneNumber,
        password: password,
      };
    }
    postRequest(url, params).then((res) => {
      if (+res?.code !== 1) {
        message.error(res?.msg || '登陆失败');
        return;
      } else {
        message.success("登陆成功");
        props.handleCloseModal();
        localStorage.setItem("token", res.data);
        localStorage.setItem("login", true);
        window.location.reload();
      }
    });
  };

  const jumpToAboutUs = () => {
    window.open("/AboutUs?val=2");
  };

  const handleSignUp = () => {
    if (loginType === "phone" && showCode) {
      return;
    }
    setShowCode(true);
    setLoginType("phone");
  };

  return (
    <Modal
      open={props.isOpen}
      onCancel={handleClose}
      footer={null} // 去掉默认的底部按钮
      maskClosable={false} // 禁止点击遮罩层关闭
      centered // 弹窗垂直居中显示
      width={400} // 弹窗宽度
    >
      {/* 弹窗内容 */}
      <div className="loginMain">
        {/* 标题 */}
        <h3 className="loginTitle">欢迎来到效果图网</h3>

        <div className="loginNoAccount">
          <div>没有账号？</div>
          <div className="loginNoAccountSpec" onClick={handleSignUp}>
            {"免费注册>>"}
          </div>
        </div>

        {loginType === "phone" ? (
          <>
            <Input
              className="loginInput"
              placeholder="请输入手机号码/邮箱"
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
            />

            {showCode ? (
              <div className="loginInputCode">
                <Input
                  placeholder="请输入验证码"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  className="code-input"
                />
                <Button
                  onClick={sendCode}
                  disabled={isLoading}
                  className="send-code-button"
                >
                  {isCodeSent
                    ? isLoading
                      ? `重新发送（${count}s）`
                      : "重新发送"
                    : "发送验证码"}
                </Button>
              </div>
            ) : null}

            <Input
              className="loginInput"
              placeholder="请输入密码"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />

            <div className="forgetPassword" onClick={handleSignUp}>
              {"忘记密码>>"}
            </div>

            <div className="loginBtn" onClick={handlePhoneLogin}>
              登录
            </div>
          </>
        ) : (
          <>
            {erweima ? (
              <div className="loginPicContainer">
                <img className="loginPic" src={erweima} />
                {erweimaInvalid ? (
                  <div className="loginPicInvalid">
                    <div className="invalidText">登录超时</div>
                    <div className="invalidText">二维码失效</div>
                    <div className="invalidBtn" onClick={refreshErweima}>
                      刷新
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="loginPicContainer" />
            )}
            <div className="loginPicTitle">
              <img className="loginPicIcon" src={weichat} />
              <div className="loginPicText">微信扫码登录/注册</div>
            </div>
          </>
        )}

        <div className="loginTypeBtnGroup">
          {loginType === "phone" ? (
            <div
              className="loginTypeBtn"
              onClick={() => changeLoginType("weichat")}
            >
              <img className="loginTypeBtnIcon" src={weichatLogo} />
              <div className="loginTypeBtnText">微信</div>
            </div>
          ) : (
            <div
              className="loginTypeBtn"
              onClick={() => changeLoginType("phone")}
            >
              <img className="loginTypeBtnIcon" src={phoneLogin} />
              <div className="loginTypeBtnText">手机</div>
            </div>
          )}
        </div>

        <div className="loginFooter">
          <div>注册登录即代表同意</div>
          <div className="footerLink" onClick={jumpToAboutUs}>
            《效果图网用户协议》
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Login;
