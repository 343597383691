import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Button, Input, Upload, message, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useUser } from "../../context";
import Login from "../../components/Login";
import { getRequest, postRequest } from "../../service/index"; // 引入封装的请求
import defaultAvatar from "../../assets/defaultAvatar.png";
import weichat from "../../assets/weichat.png";
import "./index.less";

const Setting = () => {
  const { userInfo, fetchUserInfo } = useUser();

  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [avatar, setUserAvatar] = useState(defaultAvatar);
  const [id, setUserId] = useState();
  const [nickName, setNickName] = useState("");
  const [isEditingNickName, setIsEditingNickName] = useState(false);
  const [password, setPassword] = useState("");
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [wechatStatus, setWechatStatus] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  // 绑定手机
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cancelBindDevice, setCancelBindDevice] = useState("phone");
  const [cancelBindOpen, setCancelBindOpen] = useState(false);

  // 绑定微信
  const [showBindWechat, setShowBindWechat] = useState(false);
  const [erweima, setErweima] = useState("");
  const [erweimaInvalid, setErweimaInvalid] = useState(false);
  const requestInterval = useRef(null);

  useEffect(() => {
    if (userInfo?.id) {
      setUserId(userInfo.id);
    }
    if (userInfo?.avatar) {
      setUserAvatar(userInfo.avatar);
    }
    if (userInfo?.has_password) {
      setPassword("123");
    }
    if (userInfo?.mobile) {
      setPhoneNumber(userInfo.mobile);
    }
    if (userInfo?.nickname) {
      setNickName(userInfo.nickname);
    }

    if (userInfo?.bind_wechat) {
      setWechatStatus(true);
    }
  }, [JSON.stringify(userInfo)]);

  // 自定义上传请求
  const customRequest = async ({ file, onSuccess, onError }) => {
    if (!userInfo?.id) {
      message.error("未登录");
      setIsLoginOpen(true);
      return;
    }

    if (!file) {
      message.error("请先选择图片");
      return;
    }
    const formData = new FormData();
    formData.append("avatar", file); // 将文件添加到 FormData
    postRequest("/api/user/avatar", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      message.success("上传成功");
      fetchUserInfo();
    });
  };

  const handleNickNameEdit = (flag) => {
    if (!userInfo?.id) {
      message.error("未登录");
      setIsLoginOpen(true);
      return;
    }

    if (!flag) {
      setIsEditingNickName(true);
    } else {
      postRequest("/api/user/nick_name", { nickname: nickName }).then((res) => {
        setIsEditingNickName(false);
      });
    }
  };

  const handlePasswordEdit = (flag) => {
    if (!userInfo?.id) {
      message.error("未登录");
      setIsLoginOpen(true);
      return;
    }

    if (!userInfo?.mobile) {
      message.info("请先绑定手机");
      return;
    }
    if (!flag) {
      setIsEditingPassword(true);
    } else {
      postRequest("/api/user/password", { password: password }).then((res) => {
        message.success("修改成功");
        fetchUserInfo();
        setIsEditingPassword(false);
      });
    }
  };

  // 绑定二维码
  const getErweima = () => {
    getRequest("/api/user/ticket").then((res) => {
      if (res?.data) {
        axios
          .get(
            `https://xiaoguotu.com/api/wechat/qrcode?ticket=${res.data}`,
            {
              responseType: "blob", // 设置响应类型为 blob
              headers: {
                "Content-Type": "image/jpg",
              },
            }
          )
          .then((subRes) => {
            setErweimaInvalid(false);
            let data = URL.createObjectURL(subRes.data);
            setErweima(data);
            if (!requestInterval.current) {
              requestInterval.current = setInterval(() => {
                getRequest("/api/user/bind_wechat", { ticket: res?.data }).then(
                  (finalRes) => {
                    if (finalRes.code == 1) {
                      message.success("绑定成功");
                      setWechatStatus(true);
                      handleBindWechatClose();
                    } else if (finalRes.code == -107) {
                      // 二维码失效
                      setErweimaInvalid(true);
                      clearInterval(requestInterval.current);
                      requestInterval.current = null;
                    } else if (finalRes.code !== -102) {
                      message.error(finalRes.msg);
                    }
                  }
                );
              }, 3000);
            }
          });
      }
    });
  };

  const handleBindWechatClose = () => {
    setShowBindWechat(false);
    if (requestInterval.current) {
      clearInterval(requestInterval.current);
      requestInterval.current = null;
    }
  };

  const handleBindWechat = () => {
    if (!userInfo?.id) {
      message.error("未登录");
      setIsLoginOpen(true);
      return;
    }

    if (!wechatStatus) {
      setShowBindWechat(true);
      getErweima();
    } else {
      setCancelBindDevice("wechat");
      setCancelBindOpen(true);
    }
  };

  const refreshErweima = () => {
    clearInterval(requestInterval.current);
    requestInterval.current = null;
    getErweima();
  };

  // -----绑定手机-------
  const phoneRegex = /^[1]([3-9])[0-9]{9}$/;
  const handleBindPhone = () => {
    if (!userInfo?.id) {
      message.error("未登录");
      setIsLoginOpen(true);
      return;
    }

    if (!phoneNumber) {
      setIsModalVisible(true);
    } else {
      setCancelBindDevice("phone");
      setCancelBindOpen(true);
    }
  };

  const handleConfirmCancel = () => {
    if (cancelBindDevice === "phone") {
      if (!wechatStatus) {
        message.error("解除失败！必须绑定手机、QQ、微信其中一项");
        return;
      }
      postRequest("/api/user/unbind_mobile").then((res) => {
        if (res.code == 1) {
          message.success("手机解绑成功");
          setPhoneNumber("");
          setIsCodeSent(false);
          handleCloseBind();
        } else {
          message.error(res.msg);
        }
      });
    } else {
      if (!phoneNumber) {
        message.error("解除失败！必须绑定手机、QQ、微信其中一项");
        return;
      }
      postRequest("/api/user/unbind_wechat").then((res) => {
        if (res.code == 1) {
          message.success("微信解绑成功");
          setWechatStatus(false);
          handleCloseBind();
        } else {
          message.error(res.msg);
        }
      });
    }
  };

  const handleCloseBind = () => {
    setCancelBindOpen(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setPhone("");
    setCode("");
    setIsCodeSent(false);
  };

  const sendCode = () => {
    if (!phone) {
      message.error("请输入手机号");
      return;
    }

    if (!phoneRegex.test(phone)) {
      message.error("请输入有效的手机号");
      return;
    }

    setIsLoading(true);
    // 模拟发送验证码的请求
    setTimeout(() => {
      message.success("验证码发送成功");
      setIsCodeSent(true);
      setIsLoading(false);
    }, 1000);
  };

  const handleConfirm = () => {
    if (!phone || !code) {
      message.error("请输入手机号和验证码");
      return;
    }

    if (!phoneRegex.test(phone)) {
      message.error("请输入有效的手机号");
      return;
    }

    postRequest("/api/user/bind_mobile", { phone, verification: code }).then(
      (res) => {
        if (res.code == 1) {
          setPhoneNumber(phone);
          message.success("绑定成功");
          fetchUserInfo();
          handleCancel();
        } else {
          message.error(res.msg);
        }
      }
    );
  };

  return (
    <div className="settingMain">
      <div className="account-settings">
        {/* 账号ID */}
        <div className="setting-item">
          <label>账号ID:</label>
          <div>{id}</div>
        </div>

        {/* 头像 */}
        <div className="setting-item">
          <label>头像:</label>
          <div className="avatar-section">
            <img src={avatar} className="avatar-img" />
            <Upload
              showUploadList={false}
              customRequest={customRequest}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>修改头像</Button>
            </Upload>
          </div>
        </div>

        {/* 修改昵称 */}
        <div className="setting-item">
          <label>昵称:</label>
          <div className="edit-section">
            {isEditingNickName ? (
              <Input
                value={nickName}
                onChange={(e) => setNickName(e.target.value)}
              />
            ) : (
              <div>{nickName}</div>
            )}
            <Button onClick={() => handleNickNameEdit(isEditingNickName)}>
              {isEditingNickName ? "保存" : "修改"}
            </Button>
          </div>
        </div>

        {/* 设置密码 */}
        <div className="setting-item">
          <label>密码:</label>
          <div className="edit-section">
            {isEditingPassword ? (
              <Input.Password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            ) : (
              <div>
                {phoneNumber?.length ? "******" : "请先绑定手机后再设置密码"}
              </div>
            )}
            <Button onClick={() => handlePasswordEdit(isEditingPassword)}>
              {isEditingPassword ? "保存" : "修改"}
            </Button>
          </div>
        </div>

        {/* 微信绑定 */}
        <div className="setting-item">
          <label>微信绑定:</label>
          <div className="edit-section">
            <div>{wechatStatus ? "已绑定" : "未绑定"}</div>
            <Button
              onClick={handleBindWechat}
              className={`${wechatStatus ? "cancelBindBtn" : ""}`}
            >
              {wechatStatus ? "解绑" : "绑定"}
            </Button>
          </div>
        </div>

        {/* 手机号绑定 */}
        <div className="setting-item">
          <label>手机号绑定:</label>
          <div className="edit-section">
            {phoneNumber ? (
              <div>{phoneNumber}</div>
            ) : (
              <div style={{ color: "#888" }}>
                为了您的账号安全，请及时绑定手机
              </div>
            )}
            <Button onClick={handleBindPhone}>
              {phoneNumber ? "解绑" : "绑定"}
            </Button>
          </div>
        </div>
      </div>

      <Modal
        title="绑定手机"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <div className="phoneModalContainer">
          {/* 手机号输入框 */}
          <div className="input-container">
            <Input
              placeholder="请输入手机号码"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="full-width-input"
              maxLength={11} // 限制输入的最大长度为11
            />
          </div>

          {/* 验证码输入框和发送验证码按钮 */}
          <div className="input-container code-wrapper">
            <Input
              placeholder="请输入验证码"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="code-input"
            />
            <Button
              onClick={sendCode}
              disabled={!phone || isLoading}
              className="send-code-button"
            >
              {isCodeSent ? "重新发送" : "发送验证码"}
            </Button>
          </div>

          {/* 底部的确认和取消按钮，居中显示 */}
          <div className="modal-footer">
            <Button
              key="confirm"
              type="primary"
              onClick={handleConfirm}
              className="modalBtn1"
              disabled={!phone || !code || !phoneRegex.test(phone)} // 只有手机号有效时才能点击
              style={{
                backgroundColor:
                  !phone || !code || !phoneRegex.test(phone) ? "" : "#f30",
                borderColor:
                  !phone || !code || !phoneRegex.test(phone) ? "" : "#f30",
              }}
            >
              确认
            </Button>
            <Button key="cancel" className="modalBtn1" onClick={handleCancel}>
              取消
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={showBindWechat}
        onCancel={handleBindWechatClose}
        footer={null} // 去掉默认的底部按钮
        maskClosable={false} // 禁止点击遮罩层关闭
        centered // 弹窗垂直居中显示
        width={400} // 弹窗宽度
      >
        {/* 弹窗内容 */}
        <div className="bindWechatMain">
          {/* 标题 */}
          <h3 className="bindWechatTitle">请扫码绑定微信</h3>

          <>
            {erweima ? (
              <div className="bindWechatPicContainer">
                <img className="bindWechatPic" src={erweima} />
                {erweimaInvalid ? (
                  <div className="bindWechatPicInvalid">
                    <div className="invalidText">登录超时</div>
                    <div className="invalidText">二维码失效</div>
                    <div className="invalidBtn" onClick={refreshErweima}>
                      刷新
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="bindWechatPicContainer" />
            )}
            <div className="bindWechatPicTitle">
              <img className="bindWechatPicIcon" src={weichat} />
              <div className="bindWechatPicText">微信扫码绑定</div>
            </div>
          </>
        </div>
      </Modal>

      <Modal
        title={`解绑${cancelBindDevice == "phone" ? "手机" : "微信"}`}
        open={cancelBindOpen}
        onCancel={handleCloseBind}
        footer={null}
        centered
      >
        <div className="cancelContainer">
          <div className="confirmText">
            确定取消绑定{cancelBindDevice === "phone" ? "手机" : "微信"}吗?
          </div>
          <div className="modal-footer">
            <Button
              key="confirm"
              type="primary"
              onClick={handleConfirmCancel}
              className="modalBtn1"
              style={{
                backgroundColor: "#f30",
                borderColor: "#f30",
              }}
            >
              确认
            </Button>
            <Button
              key="cancel"
              className="modalBtn1"
              onClick={handleCloseBind}
            >
              取消
            </Button>
          </div>
        </div>
      </Modal>

      <Login
        isOpen={isLoginOpen}
        handleCloseModal={() => setIsLoginOpen(false)}
      />
    </div>
  );
};

export default Setting;
