import axios from "axios";
import { message } from "antd";

let isUnauthorized = false; // 控制变量，记录是否已显示提示
// 创建 axios 实例
const instance = axios.create({
  baseURL: "https://xiaoguotu.com", // 后端服务基础路径
  timeout: 90000, // 请求超时时间
  headers: {
    "Content-Type": "application/json",
  },
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 添加token
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 处理响应数据
    return response.data;
  },
  (error) => {
    // 处理响应错误
    if (error.response) {
      // 服务器返回的错误
      console.error("Error:", error.response.status, error.response.data);
      if (error.response.status === 401) {
        if (!isUnauthorized) {
          isUnauthorized = true;
          message.error("用户登录失效，请重新登录");
          localStorage.setItem("token", "");
          if (window.setUserInfoCb) {
            window.setUserInfoCb(null);
          }
          return;
        }
      }
    } else {
      // 其他错误，如断网等
      console.error("Network Error:", error.message);
    }
    return;
  }
);

// 封装 GET 请求
export const getRequest = (url, params = {}, headers = {}) => {
  // 动态添加 headers
  const finalHeaders = {
    ...instance.defaults.headers, // 默认的 headers
    ...headers, // 传入的 headers
  };

  return instance.get(url, {
    params,
    headers: finalHeaders, // 合并后的 headers
  });
};

// 封装 POST 请求
export const postRequest = (url, data = {}, headers = {}, timeout = 10000) => {
  // 动态添加 headers
  const finalHeaders = {
    ...instance.defaults.headers, // 默认的 headers
    ...headers, // 传入的 headers
  };

  if (url == "") {
  }

  return instance.post(url, data, {
    headers: finalHeaders, // 合并后的 headers
    timeout: timeout,
  });
};

export default instance;
