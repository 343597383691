import React, { useEffect, useState } from "react";
import { Input, message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./index.less";

const AdminLogin = () => {
  const navigate = useNavigate();

  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    return () => {};
  }, []);

  const handleLogin = () => {
    axios
      .post(`https://xiaoguotu.com/api/admin/login`, {
        userName: account,
        password,
      })
      .then((res) => {
        if (!res?.data?.code || res?.data.code !== 1) {
          message.error(res?.data?.msg ? res?.data.msg : "登录失败");
          return;
        } else {
          message.success("登录成功");
          // super_admin(0, "超级管理员"),
          // common_admin(1, "普通管理员");
          localStorage.setItem("adminType", res.data.data.type);
          localStorage.setItem("adminToken", res.data.data.token);
          setTimeout(() => {
            navigate(`/secure/6/admin`);
          }, 1000);
        }
      });
  };

  return (
    <div className="adminLoginMain">
      <div className="loginHeader">管理员登录</div>

      <Input
        className="loginAccountInput"
        placeholder="请输入账号"
        onChange={(e) => setAccount(e.target.value)}
        value={account}
      />

      <Input
        className="loginPasswordInput"
        placeholder="请输入密码"
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />

      <div className="loginSubmitBtn" onClick={handleLogin}>
        登录
      </div>
    </div>
  );
};

export default AdminLogin;
