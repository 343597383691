import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Detail from "./pages/Detail";
import Layout from "./components/Layout";
import AdminLayout from "./components/AdminLayout";
import Collect from "./pages/Collect";
import History from "./pages/History";
import Download from "./pages/Download";
import Upload from "./pages/Upload";
import Profit from "./pages/Profit";
import Setting from "./pages/Setting";
import Personal from "./pages/Personal";
import Publish from "./pages/Publish";
import AboutUs from "./pages/AboutUs";
import Admin from "./pages/Admin";
import AdminLogin from "./pages/AdminLogin";
import PayForPhone from "./pages/PayForPhone";
import PayForPhoneSuccess from "./pages/PayForPhoneSuccess";
import PayForPhoneFail from "./pages/PayForPhoneFail";

function App() {
  return (
    <Routes>
      <Route exact path="/pay" element={<PayForPhone />}></Route>
      <Route exact path="/pay/success" element={<PayForPhoneSuccess />}></Route>
      <Route exact path="/pay/fail" element={<PayForPhoneFail />}></Route>
      <Route exact path="/secure/6/login" element={<AdminLogin />}></Route>
      <Route exact path="/secure/6/admin" element={<Admin />}></Route>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to="/home" replace />} />
        <Route exact path="/home" element={<Home />}></Route>
        <Route exact path="/detail" element={<Detail />}></Route>
        <Route exact path="/personal" element={<Personal />}></Route>
        <Route exact path="/aboutUs" element={<AboutUs />}></Route>
        {/* 管理后台页面，使用AdminLayout来管理左右布局 */}
        <Route path="/admin" element={<AdminLayout />}>
          <Route path="collect" element={<Collect />} />
          <Route path="download" element={<Download />} />
          <Route path="upload" element={<Upload />} />
          <Route path="profit" element={<Profit />} />
          <Route path="history" element={<History />} />
          <Route path="setting" element={<Setting />} />
          <Route path="publish" element={<Publish />} />
        </Route>
      </Route>
      {/* 处理无效路径，重定向到/home */}
      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  );
}

export default App;
