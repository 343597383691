import React, { useEffect, useState } from "react";
import { Card, Row, Col, message } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./index.less";
let token = localStorage.getItem("adminToken");

const defaultData = {
  totalOpusCnt: "-",
  totalRegisterUserCnt: "-",
  yesterdayOpusCnt: "-",
  yesterdayRegisterUserCnt: "-",
};

const Overview = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState(defaultData);

  useEffect(() => {
    token = localStorage.getItem("adminToken");
    if (props.selected === 3) {
      axios
        .get("https://xiaoguotu.com/api/admin/statistics", {
          headers: {
            Authorization: token, // 设置 Authorization 头部
          },
        })
        .then((res) => {
          setData(res?.data?.data || defaultData);
        })
        .catch((error) => {
          if (+error.response.status === 401) {
            message.error("用户登录失效，请重新登录");
            navigate(`/secure/6/login`);
          }
        });
    }
  }, [props.selected]);

  return (
    <div className="overViewMain">
      <div className="overViewTitle">数据总览</div>

      <div className="overViewContainer">
        <Row gutter={16}>
          <Col span={6}>
            <Card>
              <p style={{ fontSize: "1.6rem", color: "#888" }}>用户总数</p>
              <h3 style={{ fontSize: "3.2rem", color: "#2c3e50" }}>
                {data.totalRegisterUserCnt}
              </h3>
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <p style={{ fontSize: "1.6rem", color: "#888" }}>昨日注册数</p>
              <h3 style={{ fontSize: "3.2rem", color: "#e67e22" }}>
                {data.yesterdayRegisterUserCnt}
              </h3>
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <p style={{ fontSize: "1.6rem", color: "#888" }}>上传总作品数</p>
              <h3 style={{ fontSize: "3.2rem", color: "#27ae60" }}>
                {data.totalOpusCnt}
              </h3>
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <p style={{ fontSize: "1.6rem", color: "#888" }}>昨日上传数</p>
              <h3 style={{ fontSize: "3.2rem", color: "#c0392b" }}>
                {data.yesterdayOpusCnt}
              </h3>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Overview;
