import React from "react";
import { Outlet } from "react-router-dom";
import { Spin } from "antd";
import { useUser } from "../../context";
import Header from "../Header";
import Footer from "../Footer";
import "./index.less";

const Layout = () => {
  const { isShowMaskForWindow } = useUser();
  return (
    <div className="mainContainer">
      {isShowMaskForWindow ? (
        <div className="windowMask">
          <Spin tip="下载中，请等待" size="large">
            <div className="windowMaskLoading"></div>
          </Spin>
        </div>
      ) : null}
      <Header />
      <div className="mainContainerContent">
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
