import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.less";

const Footer = () => {
  const navigate = useNavigate();

  const jumpToBeiAn = () => {
    window.open(`https://beian.miit.gov.cn/`);
  };

  const jumpToAboutUs = (tab) => {
    navigate(`/aboutUs?tab=${tab}`);
  };

  return (
    <div className="footerMain">
      <div className="footerTop">
        <div className="footerTopItem hover" onClick={() => jumpToAboutUs(1)}>
          关于我们
        </div>
        <div className="footerTopItem hover" onClick={() => jumpToAboutUs(2)}>
          用户协议
        </div>
        <div className="footerTopItem">版权声明</div>
      </div>
      <div className="footerBottom">
        <div
          className="footerBottomItem footerBottomItemHover"
          onClick={jumpToBeiAn}
        >
          苏ICP备2024137289号-1
        </div>
        <div className="footerBottomSplit">|</div>
        <div className="footerBottomItem">Copyright © 2024 效果图网</div>
        <div className="footerBottomSplit">|</div>
        <div className="footerBottomItem">
          效果图网是网络服务平台，若您的权益被侵犯，请联系客服
        </div>
      </div>
    </div>
  );
};

export default Footer;
