import React from "react";
import logo from "../../assets/logo.png";
import paySuccess from "../../assets/paySuccess.png";
import "./index.less";

let WeixinJSBridge;

const PayForPhoneSuccess = () => {
  return (
    <div className="payMain">
      <div className="payContainer">
        <div className="payContainerCenter">
          <div className="payHeader">
            <img src={logo} className="headerLogo" />
            <div className="headerTitle">效果图网</div>
          </div>
          <img className="payStatusIcon" src={paySuccess} />
          <div className="payStatusText">订单完成</div>
        </div>
      </div>
    </div>
  );
};

export default PayForPhoneSuccess;
