import React, { useEffect, useState, useRef } from "react";
import { Button, message } from "antd";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import logo from "../../assets/logo.png";
import paySuccess from "../../assets/paySuccess.png";
import payFail from "../../assets/payFail.png";
import "./index.less";

let WeixinJSBridge;

const PayForPhone = () => {
  const [searchParams] = useSearchParams();

  const [title, settitle] = useState("");
  const [amount, setamount] = useState("-");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [pageStatus, setPageStatus] = useState("paying");

  const order = useRef("");
  const payWay = useRef("ali");

  const onBridgeReady = (res) => {
    window.WeixinJSBridge.invoke(
      "getBrandWCPayRequest",
      {
        appId: res.data.data.appId,
        nonceStr: res.data.data.nonceStr,
        package: res.data.data.packageVal,
        paySign: res.data.data.paySign,
        signType: res.data.data.signType,
        timeStamp: res.data.data.timeStamp,
      },
      function (res) {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          payState(1);
          setPageStatus("success");
        } else {
          payState(-1);
          setPageStatus("fail");
        }
      }
    );
  };

  useEffect(() => {
    setPageStatus("paying");
    let key = decodeURIComponent(searchParams.get("key"));

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/MicroMessenger/i.test(userAgent)) {
      if (!window.location.href.includes("state")) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxdd603dcc56647114&redirect_uri=https%3A%2F%2Fxiaoguotu.com%2Fpay&response_type=code&scope=snsapi_base&state=${key}#wechat_redirect`;
      } else {
        key = decodeURIComponent(searchParams.get("state"));
        payWay.current = "weixin";
        getOrderInfo(key);
      }
      return;
    } else if (/AlipayClient/i.test(userAgent)) {
      payWay.current = "ali";
      getOrderInfo(key);
      return;
    } else {
      setBtnDisabled(true);
      message.error("请通过微信/支付宝扫描二维码打开");
      return;
    }
  }, []);

  const getOrderInfo = (key) => {
    axios
      .get(`https://xiaoguotu.com/api/order/product_info`, {
        params: { key },
      })
      .then((res) => {
        settitle(res.data.data.title);
        if (res?.data?.data?.amount && parseInt(res.data.data.amount) >= 0) {
          setamount(parseInt(res.data.data.amount) / 100);
        } else {
          setamount("-");
        }
        axios
          .post(`https://xiaoguotu.com/api/order/create`, {
            key: key,
          })
          .then((response) => {
            if (response.data.data) {
              setBtnDisabled(false);
              order.current = response.data.data;
            } else {
              setBtnDisabled(true);
              setPageStatus("fail");
              message.error(response.data.msg);
              return;
            }
          })
          .catch(() => {
            setBtnDisabled(true);
            setPageStatus("fail");
            message.error("获取订单失败，请关闭页面重新扫码进入");
            return;
          });
      });
  };

  const handleClick = () => {
    if (payWay.current === "ali") {
      axios
        .get(`https://xiaoguotu.com/api/alipay/wap`, {
          params: { key: order.current },
        })
        .then((res) => {
          let divForm = document.getElementsByTagName("divform");
          if (divForm.length) {
            document.body.removeChild(divForm[0]);
          }
          const div = document.createElement("divform");
          div.innerHTML = res.data.data;
          document.body.appendChild(div);
          document.forms[0].setAttribute("target", "_blank");
          document.forms[0].submit();
          // window.ap.tradePay(
          //   {
          //     orderStr: res.data.data,
          //   },
          //   function (response) {
          //     if (+response.resultCode == 9000) {
          //       setPageStatus("success");
          //     } else {
          //       setPageStatus("fail");
          //     }
          //     payState(+response.resultCode);
          //   }
          // );
        });
    } else {
      axios
        .get(`https://xiaoguotu.com/api/wechatpay/jsapi`, {
          params: {
            key: order.current,
            code: decodeURIComponent(searchParams.get("code")),
          },
        })
        .then((res) => {
          if (typeof window.WeixinJSBridge == "undefined") {
            if (window.addEventListener) {
              window.addEventListener(
                "WeixinJSBridgeReady",
                onBridgeReady,
                false
              );
            } else if (window.attachEvent) {
              window.attachEvent("WeixinJSBridgeReady", onBridgeReady);
              window.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
            }
          } else {
            onBridgeReady(res);
          }
        })
        .catch((err) => {
          message.error(err);
        });
    }
  };

  const payState = (code) => {
    const key = decodeURIComponent(searchParams.get("state"));
    axios.post(`https://xiaoguotu.com/api/order/payState`, {
      key,
      code,
    });
  };

  return (
    <div className="payMain">
      <div className="payContainer">
        <div className="payContainerCenter">
          <div className="payHeader">
            <img src={logo} className="headerLogo" />
            <div className="headerTitle">效果图网</div>
          </div>

          {pageStatus === "success" ? (
            <img className="payStatusIcon" src={paySuccess} />
          ) : null}

          {pageStatus === "success" ? (
            <div className="payStatusText">订单完成</div>
          ) : null}

          {pageStatus === "fail" ? (
            <img className="payStatusIcon" src={payFail} />
          ) : null}

          {pageStatus === "fail" ? (
            <div className="payStatusText">订单失败</div>
          ) : null}

          {pageStatus === "paying" ? (
            <div className="payTitle">{title}</div>
          ) : null}

          {pageStatus === "paying" ? (
            <div
              className={`priceContainer ${
                amount.toString().length > 5 ? "priceContainerSmall" : ""
              }`}
            >
              <span className="priceIcon">￥</span> {amount}
            </div>
          ) : null}

          {pageStatus === "paying" ? (
            <Button
              onClick={handleClick}
              className={`btnClass ${btnDisabled ? "btnClassDisabled" : ""}`}
              disabled={btnDisabled}
            >
              立即支付
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PayForPhone;
