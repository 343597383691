import React, { useEffect, useState } from "react";
import { Table, Button, message, Modal, Input } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./index.less";

let token = localStorage.getItem("adminToken");
let id = "";

const AdminManager = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    token = localStorage.getItem("adminToken");
    if (props.selected === 5) {
      fetchData();
    }
  }, [props.selected]);

  const fetchData = () => {
    axios
      .get("https://xiaoguotu.com/api/admin/admins", {
        headers: {
          Authorization: token, // 设置 Authorization 头部
        },
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        if (+error.response.status === 401) {
          message.error("用户登录失效，请重新登录");
          navigate(`/secure/6/login`);
        }
      });
  };

  const handleDelete = (data) => {
    axios
      .post(
        "https://xiaoguotu.com/api/admin/delete",
        {
          id: data.id,
        },
        {
          headers: {
            Authorization: token, // 设置 Authorization 头部
          },
        }
      )
      .then((res) => {
        if (!res?.data?.code || res?.data.code !== 1) {
          message.error(res?.data?.msg ? res?.data.msg : "删除失败");
          return;
        } else {
          message.success("删除成功");
          fetchData();
        }
      })
      .catch((error) => {
        if (+error.response.status === 401) {
          message.error("用户登录失效，请重新登录");
          navigate(`/secure/6/login`);
        }
        return;
      });
  };

  const handleEdit = (data) => {
    id = data.id;
    setAccount(data.userName);
    setPassword(data.password);
    setIsEdit(true);
    setIsOpen(true);
  };

  const handleSubmit = () => {
    let url = "https://xiaoguotu.com/api/admin/add";
    if (isEdit) {
      url = "https://xiaoguotu.com/api/admin/update";
    }
    axios
      .post(
        url,
        {
          userName: account,
          password,
          type: 1,
          id: isEdit ? id : "",
        },
        {
          headers: {
            Authorization: token, // 设置 Authorization 头部
          },
        }
      )
      .then((res) => {
        if (!res?.data?.code || res?.data.code !== 1) {
          message.error(res?.data?.msg ? res?.data.msg : "操作失败");
          return;
        } else {
          message.success("操作成功");
          setIsEdit(false);
          setIsOpen(false);
          id = "";
          setAccount("");
          setPassword("");
          fetchData();
        }
      })
      .catch((error) => {
        if (+error.response.status === 401) {
          message.error("用户登录失效，请重新登录");
          navigate(`/secure/6/login`);
        }
        return;
      });
  };

  const columns = [
    {
      title: "账号",
      dataIndex: "userName",
      width: "20%",
    },
    {
      title: "密码",
      dataIndex: "password",
      width: "20%",
    },
    {
      title: "类别",
      dataIndex: "type",
      render: (data) => {
        const statusMap = { 0: "超级管理员", 1: "普通管理员" };
        return <span>{statusMap[data]}</span>;
      },
    },
    {
      title: "操作",
      dataIndex: "action",
      render: (_, record) => {
        if (record.type !== 0) {
          return (
            <div style={{ display: "flex" }}>
              <div
                onClick={() => handleDelete(record)}
                style={{ cursor: "pointer", marginRight: 8 }}
              >
                删除
              </div>
              <div
                onClick={() => handleEdit(record)}
                style={{ cursor: "pointer", marginRight: 8 }}
              >
                编辑
              </div>
            </div>
          );
        }
      },
    },
  ];

  const handleAdd = () => {
    setIsEdit(false);
    id = "";
    setIsOpen(true);
  };

  const handleClose = () => {
    id = "";
    setIsOpen(false);
    setIsEdit(false);
  };

  return (
    <div className="adminManagerMain">
      <div className="adminManagerTitle">管理员列表</div>
      <Button
        key="submit"
        type="primary"
        onClick={handleAdd}
        className="adminModalBtnAdd"
      >
        添加
      </Button>

      <Table
        columns={columns}
        scroll={{ y: 800 }}
        dataSource={data}
        pagination={false}
        locale={{ emptyText: "暂无数据" }}
      />

      <Modal
        title={isEdit ? "编辑管理员" : "添加管理员"}
        open={isOpen}
        centered
        onCancel={handleClose}
        footer={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              className="adminModalBtn"
            >
              确定
            </Button>
          </div>
        }
      >
        <div className="adminModalLine">
          <div className="adminModalLabel">账号：</div>
          <Input
            value={account}
            className="adminModalInput"
            onChange={(e) => setAccount(e.target.value)}
            placeholder="请输入管理员账号"
          />
        </div>
        <div className="adminModalLine">
          <div className="adminModalLabel">密码：</div>
          <Input
            value={password}
            className="adminModalInput"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="请输入管理员密码"
          />
        </div>
      </Modal>
    </div>
  );
};

export default AdminManager;
