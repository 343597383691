import React, { useEffect, useState } from "react";
import { Input, Modal, message, Button, Upload } from "antd";
import axios from "axios";
import touxiang from "../../assets/touxiang.png";
import guohui from "../../assets/guohui.png";
import { getRequest, postRequest } from "../../service/index"; // 引入封装的请求
import "./index.less";

const PersonalVertification = (props) => {
  let formData = new FormData();
  const [name, setName] = useState("");
  const [account, setAccount] = useState("");
  const [idCardFront, setIdCardFront] = useState(guohui);
  const [idCardFrontUpload, setIdCardFrontUpload] = useState(false);
  const [frontFile, setFrontFile] = useState(null);
  const [idCardBack, setIidCardBack] = useState(touxiang);
  const [idCardBackUpload, setIidCardBackUpload] = useState(false);
  const [backFile, setBackFile] = useState(null);

  useEffect(() => {
    formData = new FormData();
  }, [props.isOpen]);

  const handleIconChange = ({ file, onSuccess, onError }, flag) => {
    if (!file) {
      message.error("请先选择图片");
      return;
    }

    if (file instanceof Blob || file instanceof File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        if (flag === "front") {
          setIdCardFront(imageUrl);
          setFrontFile(file);
          setIdCardFrontUpload(true);
        } else {
          setIidCardBack(imageUrl);
          setBackFile(file);
          setIidCardBackUpload(false);
        }
      };
      reader.readAsDataURL(file); // 正确获取 file 对象进行读取
    }
  };

  const handleSubmit = () => {
    if (!name) {
      message.error("请填写姓名");
      return;
    }
    if (!account) {
      message.error("请填写账号");
      return;
    }
    if (!idCardFrontUpload) {
      message.error("请上传身份证正面（国徽面）照片");
      return;
    }
    if (!idCardBackUpload) {
      message.error("请上传身份证反面（头像）照片");
      return;
    }

    formData.append("aliPayAccount", account);
    formData.append("realName", name);
    formData.append("front", frontFile);
    formData.append("back", backFile);

    postRequest("/api/profit/withdrawal", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      if (!response?.code || response.code !== 1 || !response?.data) {
        message.error(response?.msg ? response.msg : "申请提现失败");
        return;
      } else {
        message.success("申请提现成功，请等待审核");
        setName("");
        setAccount("");
        setIdCardFront(guohui);
        setIdCardFrontUpload(false);
        setIidCardBack(touxiang);
        setIidCardBackUpload(false);
        props.handleClose(true);
      }
    });
  };

  const onClose = () => {
    setName("");
    setAccount("");
    setIdCardFront(guohui);
    setIdCardFrontUpload(false);
    setIidCardBack(touxiang);
    setIidCardBackUpload(false);
    props.handleClose(false);
  };

  return (
    <Modal
      open={props.isOpen}
      onCancel={onClose}
      title="提现认证"
      maskClosable={false} // 禁止点击遮罩层关闭
      centered // 弹窗垂直居中显示
      width={500} // 弹窗宽度
      footer={
        <div className="vemodalFooter">
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            className="modalBtn"
          >
            提交认证
          </Button>
        </div>
      }
    >
      <div className="vemodalContainer">
        <div className="vemodalItem">
          <div className="vemodalItemLabel">姓名：</div>
          <div className="vemodalItemInput">
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </div>
        </div>
        <div className="vemodalItem">
          <div className="vemodalItemLabel">支付宝账号：</div>
          <div className="vemodalItemInput">
            <Input
              value={account}
              onChange={(e) => setAccount(e.target.value)}
            />
          </div>
        </div>

        <div className="vemodalItemNoWrap">
          <div className="vemodalItemLabel">上传身份证：</div>
          <div className="vemodalItemUpload">
            <div>
              <Upload
                listType="picture-card"
                showUploadList={false}
                className="vemodalItemUploadBtnContainer"
                customRequest={(_) => handleIconChange(_, "front")}
                maxCount={1}
              >
                <div className="vemodalItemUploadBtn">
                  <img className="uploadPic" src={idCardFront} />
                </div>
              </Upload>
              <div className="uploadTitle">国徽面</div>
            </div>

            <div>
              <Upload
                listType="picture-card"
                showUploadList={false}
                className="vemodalItemUploadBtnContainer"
                customRequest={(_) => handleIconChange(_, "back")}
                maxCount={1}
              >
                <div className="vemodalItemUploadBtn">
                  <img className="uploadPic" src={idCardBack} />
                </div>
              </Upload>
              <div className="uploadTitle">头像面</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PersonalVertification;
