import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CategoriesConfig from "./components/CategoriesConfig";
import Examine from "./components/Examine";
import Overview from "./components/Overview";
import WithdrawalReview from "./components/WithdrawalReview";
import AdminManager from "./components/AdminManager";
import logo from "../../assets/logo.png";
import "./index.less";

const Admin = () => {
  const navigate = useNavigate();

  const [selected, setSelected] = useState(1);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (+localStorage.getItem("adminType") == 0) {
      setItems([
        { id: 1, label: "分类标签配置" },
        { id: 2, label: "作品审核" },
        { id: 3, label: "数据总览" },
        { id: 4, label: "提现审核" },
        { id: 5, label: "管理员配置" },
      ]);
    } else {
      setItems([
        { id: 1, label: "分类标签配置" },
        { id: 2, label: "作品审核" },
        { id: 3, label: "数据总览" },
        { id: 4, label: "提现审核" },
      ]);
    }
    return () => {
      localStorage.removeItem("adminToken");
      localStorage.removeItem("adminType");
    };
  }, []);

  const jumpToHome = () => {
    navigate(`/home`);
  };

  const handleClick = (item) => {
    setSelected(item.id);
  };

  return (
    <div className="adminMain">
      <div className="adminHeader">
        <div className="headerLeft" onClick={jumpToHome}>
          <img src={logo} className="headerLogo" alt="" />
          <div className="headerTitle">效果图网</div>
        </div>
      </div>

      <div className="adminContainer">
        <div className="leftNav">
          {items.map((item, index) => (
            <div
              key={`${item.id}${index}`}
              className={`navItem ${selected === item.id ? "selected" : ""}`}
              onClick={() => handleClick(item)}
            >
              {item.label}
            </div>
          ))}
        </div>

        <div className="rightContainer">
          {selected === 1 ? <CategoriesConfig selected={selected} /> : null}
          {selected === 2 ? <Examine selected={selected} /> : null}
          {selected === 3 ? <Overview selected={selected} /> : null}
          {selected === 4 ? <WithdrawalReview selected={selected} /> : null}
          {selected === 5 ? <AdminManager selected={selected} /> : null}
        </div>
      </div>
    </div>
  );
};

export default Admin;
