import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination, Table, Button, message, Modal } from "antd";
import axios from "axios";
import "./index.less";

let token = localStorage.getItem("adminToken");

const getStatusText = (status) => {
  switch (status) {
    case 1:
      return { text: "审核中", color: "#d39e00" };
    case 2:
      return { text: "已打款", color: "#28a745" };
    case 3:
      return { text: "审核不通过", color: "#f30" };
    default:
      return { text: "未知状态", color: "#000" };
  }
};

const WithdrawalReview = () => {
  const navigate = useNavigate();
  const columns = [
    {
      title: "姓名",
      dataIndex: "realName",
      width: "12%",
    },
    {
      title: "账户余额（元）",
      dataIndex: "balance",
      width: "15%",
      render: (data) => {
        return <span>￥{data / 100}</span>;
      },
    },
    {
      title: "提现总额（元）",
      width: "15%",
      dataIndex: "withdrawalAmount",
      render: (data) => {
        return <span>￥{data / 100}</span>;
      },
    },
    {
      title: "提现时间",
      width: "20%",
      dataIndex: "withdrawalTime",
    },
    {
      title: "审核状态",
      width: "12%",
      dataIndex: "status",
      render: (text) => {
        const { text: statusText, color } = getStatusText(text);
        return <span style={{ color }}>{statusText}</span>;
      },
    },
    {
      title: "审核操作",
      dataIndex: "action",
      render: (_, record) => {
        if (record.status == 1) {
          return (
            <div className="optionBtnGroup">
              {record.balance >= record.withdrawalAmount ? (
                <div className="linkBtn" onClick={() => optionCheck(record, 2)}>
                  已打款
                </div>
              ) : null}

              <div className="linkBtn" onClick={() => optionCheck(record, 3)}>
                不通过
              </div>
              <div className="linkBtn" onClick={() => checkDetail(record)}>
                用户详情
              </div>
            </div>
          );
        } else {
          return (
            <div className="optionBtnGroup">
              <div className="linkBtn" onClick={() => checkDetail(record)}>
                用户详情
              </div>
            </div>
          );
        }
      },
    },
  ];

  const [tabVal, setTabVal] = useState(0);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(100);
  const [pageNum, setPageNum] = useState(1);
  const [userInfo, setUserInfo] = useState({});
  const [userDetailOpen, setUserDetailOpen] = useState(false);

  useEffect(() => {
    token = localStorage.getItem("adminToken");
    fetchData(0, 0);
  }, []);

  const fetchData = (page, status) => {
    axios
      .get("https://xiaoguotu.com/api/admin/audit_withdrawals", {
        params: {
          page,
          size: 5,
          status,
        },
        headers: {
          Authorization: token, // 设置 Authorization 头部
        },
      })
      .then((res) => {
        if (res?.data) {
          setData(res?.data.data.list || []);
          setTotal(res?.data.data.total);
        }
      })
      .catch((error) => {
        if (+error.response.status === 401) {
          message.error("用户登录失效，请重新登录");
          navigate(`/secure/6/login`);
        }
      });
  };

  const onTabChange = (val) => {
    setTabVal(val);
    setPageNum(1);
    fetchData(0, val);
  };

  const onPageChange = (page) => {
    setPageNum(page);
    fetchData(page - 1, tabVal);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>上一页</a>;
    }
    if (type === "next") {
      return <a>下一页</a>;
    }
    return originalElement;
  };

  const checkDetail = (data) => {
    setUserInfo(data);
    setUserDetailOpen(true);
  };

  const optionCheck = (data, status) => {
    axios
      .post(
        "https://xiaoguotu.com/api/admin/audit_withdrawal",
        { id: data.id, status },
        {
          headers: {
            Authorization: token, // 设置 Authorization 头部
          },
        }
      )
      .then((res) => {
        if (!res || !res?.data) {
          message.error(res?.data?.msg || "操作失败");
        } else {
          message.success("操作成功");
          setPageNum(1);
          fetchData(0, tabVal);
        }
      })
      .catch((error) => {
        if (+error.response.status === 401) {
          message.error("用户登录失效，请重新登录");
          navigate(`/secure/6/login`);
        }
      });
  };

  return (
    <div className="withdrawalReviewMain">
      <div className="title">提现审核</div>
      <div className="tabMain">
        <div
          key={`withdrawal${0}`}
          className={`${tabVal === 0 ? "active" : ""} tabItem`}
          onClick={() => onTabChange(0)}
        >
          全部
        </div>
        <div
          key={`withdrawal${1}`}
          className={`${tabVal === 1 ? "active" : ""} tabItem`}
          onClick={() => onTabChange(1)}
        >
          审核中
        </div>
        <div
          key={`withdrawal${2}`}
          className={`${tabVal === 2 ? "active" : ""} tabItem`}
          onClick={() => onTabChange(2)}
        >
          已打款
        </div>
        <div
          key={`withdrawal${3}`}
          className={`${tabVal === 3 ? "active" : ""} tabItem`}
          onClick={() => onTabChange(3)}
        >
          审核不通过
        </div>
      </div>

      <div className="dataContainer">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          locale={{ emptyText: "暂无数据" }}
        />
      </div>

      {total > 3 ? (
        <Pagination
          className="paginationContainer"
          total={total}
          current={pageNum}
          itemRender={itemRender}
          showSizeChanger={false}
          onChange={onPageChange}
        />
      ) : null}

      <Modal
        title="用户信息"
        open={userDetailOpen}
        centered
        onCancel={() => setUserDetailOpen(false)}
        footer={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              key="submit"
              type="primary"
              onClick={() => setUserDetailOpen(false)}
              className="modalBtn"
            >
              关闭
            </Button>
          </div>
        }
      >
        <div style={{ marginBottom: "2rem", marginTop: "4rem" }}>
          <strong>姓名：</strong> {userInfo.realName}
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <strong>支付宝账号：</strong> {userInfo.aliPayAccount}
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <strong>身份证：</strong>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem",
            }}
          >
            <img
              src={userInfo.idCardFront}
              style={{ width: "45%", borderRadius: "4px", display: "block" }}
            />
            <img
              src={userInfo.idCardBack}
              style={{ width: "45%", borderRadius: "4px", display: "block" }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WithdrawalReview;
