import React, { useEffect, useState } from "react";
import { Pagination, Table, message, Tooltip } from "antd";
import PersonalVertification from "../../components/PersonalVertification";
import { getRequest } from "../../service/index"; // 引入封装的请求
import tips from "../../assets/tips.png";
import "./index.less";

const getStatusText = (status) => {
  switch (status) {
    case 1:
      return { text: "审核中", color: "#d39e00" };
    case 2:
      return { text: "已打款", color: "#28a745" };
    case 3:
      return { text: "审核不通过", color: "#f30" };
    default:
      return { text: "未知状态", color: "#000" };
  }
};

const columns0 = [
  {
    title: "封面图",
    dataIndex: "cover",
    key: "cover",
    align: "center",
    render: (cover) => (
      <img src={cover} alt="cover" style={{ width: "10rem", height: "8rem" }} />
    ),
  },
  {
    title: "标题",
    dataIndex: "title",
    key: "title",
    align: "center",
    render: (_, record) => (
      <div>
        <div>{record.title}</div> {/* 第一行显示标题 */}
        <div style={{ fontSize: "1.2rem", color: "#888" }}>
          ID: {record.opusId}
        </div>
      </div>
    ),
  },
  {
    title: "收入（元）",
    align: "center",
    dataIndex: "amount",
    render: (_, record) => <div>￥{_ / 100}</div>,
    key: "amount",
  },
  {
    title: "交易时间",
    align: "center",
    dataIndex: "tradeTime",
    key: "tradeTime",
  },
];

const columns1 = [
  {
    title: "提现支付宝账号",
    align: "center",
    dataIndex: "aliPayAccount",
    key: "aliPayAccount",
  },
  {
    title: "提现金额（元）",
    dataIndex: "amount",
    key: "amount",
    render: (_, record) => (
      <div style={{ textAlign: "center" }}>￥{_ / 100}</div>
    ),
  },
  {
    title: "提现时间",
    align: "center",
    dataIndex: "time",
    key: "time",
  },
  {
    title: "提现状态",
    align: "center",
    dataIndex: "status",
    key: "status",
    render: (text) => {
      const { text: statusText, color } = getStatusText(text);
      return <span style={{ color }}>{statusText}</span>;
    },
  },
];

const Profit = () => {
  const [profit, setProfit] = useState(0);
  const [profitYesterday, setProfitYesterday] = useState("0");
  const [profitMonth, setProfitMonth] = useState("0");
  const [profitTotal, setProfitTotal] = useState("0");
  const [canWithdrawal, setCanWithdrawal] = useState(false);
  const [btn2Status, setBtn2Status] = useState(0); // 0-提现明细;1-收益中心
  const [data, setData] = useState([]);
  const [column, setColumn] = useState(columns0);
  const [isVertiOpen, setIsVertiOpen] = useState(false);

  const [total, setTotal] = useState(0);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    getRequest("/api/profit/income").then((res) => {
      if (res?.data) {
        setProfitTotal(res.data.amount / 100);
        setProfitMonth(res.data.curMonthAmount / 100);
        setProfitYesterday(res.data.yesterdayAmount / 100);
        setProfit(res.data.balance / 100);
        setCanWithdrawal(res.data.canWithdrawal);
      }
    });
    fetchData0(0);
  }, []);

  const fetchData0 = (page) => {
    getRequest("/api/profit/income_details", {
      page,
      size: 5,
    }).then((res) => {
      if (res.data) {
        setData(res.data.list || []);
        setTotal(res.data.total);
      }
    });
  };

  const fetchData1 = (page) => {
    getRequest("/api/profit/withdrawal_details", {
      page,
      size: 5,
    }).then((res) => {
      if (res.data) {
        setData(res.data.list || []);
        setTotal(res.data.total);
      }
    });
  };

  const onPageChange = (page) => {
    setPageNum(page);
    if (btn2Status == 0) {
      fetchData0(page - 1);
    } else {
      fetchData1(page - 1);
    }
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>上一页</a>;
    }
    if (type === "next") {
      return <a>下一页</a>;
    }
    return originalElement;
  };

  const handleBtn2Clicked = (val) => {
    setBtn2Status(val);
    setPageNum(1);
    setColumn(val === 0 ? columns0 : columns1);
    if (val === 0) {
      fetchData0(0);
    } else {
      fetchData1(0);
    }
    setData([]);
  };

  const vertiApply = () => {
    if (!canWithdrawal) {
      message.error("暂不可提现");
      return;
    }
    setIsVertiOpen(true);
  };

  const handleVertiClose = (flag) => {
    if (flag) {
      setCanWithdrawal(false);
    }
    setIsVertiOpen(false);
  };

  return (
    <div className="profitMain">
      <div className="profitHeader">
        <div className="profitValue">
          <div className="profitValContainer">
            <div className="profitVal">{profit}</div>
            <div className="profitUnit">元</div>
          </div>
          <div className="btnGroup">
            <div
              className={`btnLeft ${canWithdrawal ? "" : "btnLeftDisabled"}`}
            >
              <div
                className="btnLeftText"
                onClick={vertiApply}
                disabled={!canWithdrawal}
              >
                申请提现
              </div>
              <Tooltip
                title="每月1-5号为申请提现时间，审核通过后当月20号打款。收益满50元方可提现，提现需要实名认证"
                placement="bottom" // 提示框的位置
              >
                <img className="btnLeftIcon" src={tips} />
              </Tooltip>
            </div>
            <div
              className="btnRight"
              onClick={() => handleBtn2Clicked(btn2Status === 0 ? 1 : 0)}
            >
              {btn2Status === 0 ? "提现明细" : "收益中心"}
            </div>
          </div>
        </div>
        <div className="profitYesterday">
          <div className="itemLabel">昨日收益：+</div>
          <div className="itemValue">{profitYesterday}</div>
          <div className="itemLabel">元</div>
        </div>
        <div className="profitMonth">
          <div className="itemLabel">本月收益：+</div>
          <div className="itemValue">{profitMonth}</div>
          <div className="itemLabel">元</div>
        </div>
        <div className="profitTotal">
          <div className="itemLabel">累计收益：+</div>
          <div className="itemValue">{profitTotal}</div>
          <div className="itemLabel">元</div>
        </div>
      </div>

      <Table
        dataSource={data}
        columns={column}
        pagination={false}
        bordered={false} // 取消表格边框
        className="custom-table"
        locale={{ emptyText: "暂无收益记录" }}
      />

      {total > 5 ? (
        <Pagination
          className="paginationContainer"
          total={total}
          current={pageNum}
          itemRender={itemRender}
          showSizeChanger={false}
          onChange={onPageChange}
        />
      ) : null}

      <PersonalVertification
        handleClose={handleVertiClose}
        isOpen={isVertiOpen}
      />
    </div>
  );
};

export default Profit;
